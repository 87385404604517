import Vue from "vue";
import Router from "vue-router";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import store from "@/core/services/store";
import i18n from "@/core/plugins/vue-i18n";
import CoreSettingsJs from "@shared/src/core/services/settings.js";

Vue.use(Router);
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

export default new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			redirect: "/home",
			meta: {
				requiresAuth: false,
				title: i18n.t("COMMON.META_TITLE", { page_title: i18n.t("HEADER.HOME") }),
			},
			component: () => import("@/view/layout/LayoutWithStaticSideBar"),
			children: [
				{
					path: "/home",
					name: "home",
					meta: {
						requiresAuth: false,
						title: i18n.t("COMMON.META_TITLE", { page_title: i18n.t("HEADER.HOME") }),
						home: 1
					},
					component: () => import("@/view/pages/Home.vue")
				},
				{
					path: "/dashboard",
					name: "dashboard",
					meta: {
						requiresAuth: true,
						title: i18n.t("COMMON.META_TITLE", { page_title: i18n.t("CUSTOMER.SIDE_BAR.DASHBOARD") })
					},
					component: () => import("@/view/pages/dashboard/Dashboard.vue")
				},
				{
					path: "/request-for-quote",
					name: "request-for-quote",
					meta: {
						title: i18n.t("COMMON.META_TITLE", {
							page_title: i18n.t("QUOTATION_MANAGEMENT.INQUIRY_QUOTATION.REQUEST_FOR_QUOTE_TITLE")
						})
					},
					component: () => import("@/view/pages/inquiry_quotation/RequestQuoteForm.vue"),
					beforeEnter(to, _from, next) {
						if (!store.getters.isAuthenticated) {
							Swal.fire({
								icon: "error",
								text: i18n.t("AUTH.LOGIN.LOGIN_REQUIRED")
							});
							next("login");
						} else if (!store.getters.isAccountExists) {
							next("/user/setup-first-account");
						} else {
							next();
						}
					}
				},
				{
					path: "/copy-quote/:id",
					name: "copy-quote",
					meta: {
						title: i18n.t("COMMON.META_TITLE", {
							page_title: i18n.t("QUOTATION_MANAGEMENT.INQUIRY_QUOTATION.REQUEST_FOR_QUOTE_TITLE")
						}),
						copy: 1
					},
					component: () => import("@/view/pages/inquiry_quotation/RequestQuoteForm.vue"),
					beforeEnter(to, _from, next) {
						if (!store.getters.isAuthenticated) {
							Swal.fire({
								icon: "error",
								text: i18n.t("AUTH.LOGIN.LOGIN_REQUIRED")
							});
							next("login");
						} else if (!store.getters.isAccountExists) {
							next("/user/setup-first-account");
						} else {
							next();
						}
					}
				},
				{
					path: "/edit-inquiry-quote/:id",
					name: "edit-inquiry",
					meta: {
						title: i18n.t("COMMON.META_TITLE", {
							page_title: i18n.t("QUOTATION_MANAGEMENT.INQUIRY_QUOTATION.REQUEST_FOR_QUOTE_TITLE")
						})
					},
					component: () => import("@/view/pages/inquiry_quotation/RequestQuoteForm.vue"),
					beforeEnter(to, _from, next) {
						if (!store.getters.isAuthenticated) {
							Swal.fire({
								icon: "error",
								text: i18n.t("AUTH.LOGIN.LOGIN_REQUIRED")
							});
							next("login");
						} else if (!store.getters.isAccountExists) {
							next("/user/setup-first-account");
						} else {
							next();
						}
					}
				},
				{
					path: "/quote-history/:id",
					name: "quote-history",
					meta: {
						title: i18n.t("COMMON.META_TITLE", {
							page_title: i18n.t("QUOTATION_MANAGEMENT.INQUIRY_QUOTATION.BTN_VIEW_HISTORY")
						})
					},
					component: () => import("@/view/pages/inquiry_quotation/ViewQuoteForm.vue"),
					beforeEnter(to, _from, next) {
						if (!store.getters.isAuthenticated) {
							Swal.fire({
								icon: "error",
								text: i18n.t("AUTH.LOGIN.LOGIN_REQUIRED")
							});
							next("login");
						} else if (!store.getters.isAccountExists) {
							next("/user/setup-first-account");
						} else {
							next();
						}
					}
				},
				{
					path: "/print-quote-history/:id",
					name: "print-quote-history",
					meta: {
						title: i18n.t("COMMON.META_TITLE", {
							page_title: i18n.t("QUOTATION_MANAGEMENT.INQUIRY_QUOTATION.BTN_PRINT_QUOTATION")
						})
					},
					component: () => import("@/view/pages/inquiry_quotation/QuotationDetail.vue"),
					beforeEnter(to, _from, next) {
						if (!store.getters.isAuthenticated) {
							Swal.fire({
								icon: "error",
								text: i18n.t("AUTH.LOGIN.LOGIN_REQUIRED")
							});
							next("login");
						} else if (!store.getters.isAccountExists) {
							next("/user/setup-first-account");
						} else {
							next();
						}
					}
				},
				{
					path: "/inquiry-quote-detail/:id",
					name: "inquiry-quote-detail",
					meta: {
						title: i18n.t("COMMON.META_TITLE", {
							page_title: i18n.t("QUOTATION_MANAGEMENT.INQUIRY_QUOTATION.INQUIRY_QUOTATION_SUMMARY")
						})
					},
					component: () => import("@/view/pages/inquiry_quotation/ViewQuoteForm.vue"),
					beforeEnter(to, _from, next) {
						if (!store.getters.isAuthenticated) {
							Swal.fire({
								icon: "error",
								text: i18n.t("AUTH.LOGIN.LOGIN_REQUIRED")
							});
							next("login");
						} else if (!store.getters.isAccountExists) {
							next("/user/setup-first-account");
						} else {
							next();
						}
					}
				},
				{
					path: "/quote-listing",
					name: "quote-listing",
					meta: {
						title: i18n.t("COMMON.META_TITLE", {
							page_title: i18n.t("QUOTATION_MANAGEMENT.INQUIRY_QUOTATION.INQUIRY_QUOTATION_TITLE")
						})
					},
					component: () => import("@/view/pages/inquiry_quotation/RequestQuoteListing.vue"),
					beforeEnter(to, _from, next) {
						if (!store.getters.isAuthenticated) {
							Swal.fire({
								icon: "error",
								text: i18n.t("AUTH.LOGIN.LOGIN_REQUIRED")
							});
							next("login");
						} else if (!store.getters.isAccountExists) {
							next("/user/setup-first-account");
						} else {
							next();
						}
					}
				},
				{
					path: "/quotation-acknowledge/:id",
					name: "acknowledge",
					meta: {
						title: i18n.t("COMMON.META_TITLE", {
							page_title: i18n.t("QUOTATION_MANAGEMENT.INQUIRY_QUOTATION.BTN_ACKNOWLEDGE")
						})
					},
					component: () => import("@/view/pages/inquiry_quotation/QuotationDetail.vue"),
					beforeEnter(to, _from, next) {
						if (!store.getters.isAuthenticated) {
							Swal.fire({
								icon: "error",
								text: i18n.t("AUTH.LOGIN.LOGIN_REQUIRED")
							});
							next("login");
						} else if (!store.getters.isAccountExists) {
							next("/user/setup-first-account");
						} else {
							next();
						}
					}
				},
				{
					path: "/quotation-detail/:id",
					name: "detail",
					meta: {
						title: i18n.t("COMMON.META_TITLE", {
							page_title: i18n.t("QUOTATION_MANAGEMENT.QUOTATION_DETAIL.ACKNOWLEDGE_QUOTATION_TITLE")
						})
					},
					component: () => import("@/view/pages/inquiry_quotation/QuotationDetail.vue"),
					beforeEnter(to, _from, next) {
						if (!store.getters.isAuthenticated) {
							Swal.fire({
								icon: "error",
								text: i18n.t("AUTH.LOGIN.LOGIN_REQUIRED")
							});
							next("login");
						} else if (!store.getters.isAccountExists) {
							next("/user/setup-first-account");
						} else {
							next();
						}
					}
				},
				{
					path: "page/:slug",
					name: "cms-page",
					meta: {
						requiresAuth: false
					},
					component: () => import("@/view/pages/cms/CmsPage")
				},
				{
					path: "epo",
					redirect: "epo/listing",
					component: {
						render(c) {
							return c("router-view");
						}
					},
					children: [
						{
							path: "listing",
							name: "epo-listing",
							meta: {
								title: i18n.t("EPO_MANAGEMENT.ORDER.LISTING_TITLE")
							},
							component: () => import("@/view/pages/epo/EpoListing.vue"),
							beforeEnter(to, _from, next) {
								if (!store.getters.isAccountExists) {
									next("/user/setup-first-account");
								} else {
									next();
								}
							}
						},
						{
							path: "create",
							name: "epo-create",
							meta: {
								title: i18n.t("EPO_MANAGEMENT.ORDER.CREATE_ORDER_TITLE")
							},
							component: () => import("@/view/pages/epo/EpoForm.vue"),
							beforeEnter(to, _from, next) {
								if (!store.getters.isAuthenticated) {
									alert("456");
									Swal.fire({
										icon: "warnig",
										title: i18n.t("AUTH.LOGIN.LOGIN_REQUIRED"),
										html: i18n.t('AUTH.LOGIN.SESSION_TIMEOUT_MSG')
									}).then(() => {
										next("login");
									});

								} else if (!store.getters.isAccountExists) {
									next("/user/setup-first-account");
								} else {
									next();
								}
							}
						},
						{
							path: "edit/:id",
							name: "epo-edit",
							component: () => import("@/view/pages/epo/EpoForm.vue"),
							beforeEnter(to, _from, next) {
								if (!store.getters.isAccountExists) {
									next("/user/setup-first-account");
								} else {
									next();
								}
							}
						},
						{
							path: "view/:id",
							name: "epo-view",
							meta: {
								title: i18n.t("COMMON.META_TITLE", {
									page_title: i18n.t("EPO_MANAGEMENT.EPO_LISTING.BTN_VIEW")
								})
							},
							component: () => import("@/view/pages/epo/EpoForm.vue"),
							beforeEnter(to, _from, next) {
								if (!store.getters.isAccountExists) {
									next("/user/setup-first-account");
								} else {
									next();
								}
							}
						},
						{
							path: "copy-epo/:id",
							name: "copy-epo",
							meta: {
								title: i18n.t("COMMON.META_TITLE", {
									page_title: i18n.t("EPO_MANAGEMENT.ORDER.EPO_ORDER_COPY_TITLE")
								})
							},
							component: () => import("@/view/pages/epo/EpoForm.vue"),
							beforeEnter(to, _from, next) {
								if (!store.getters.isAccountExists) {
									next("/user/setup-first-account");
								} else {
									next();
								}
							}
						},
						{
							path: "format/:id",
							name: "epo-format",
							component: () => import("@/view/pages/epo/EpoFormat.vue"),
							beforeEnter(to, _from, next) {
								if (!store.getters.isAccountExists) {
									next("/user/setup-first-account");
								} else {
									next();
								}
							}
						}
					]
				},
				{
					path: "/user",
					redirect: "user/profile/",
					name: "user",
					meta: {
						title: i18n.t("COMMON.META_TITLE", {
							page_title: i18n.t("HEADER.QUICK_USER.QUICK_USER_TITLE")
						}),
						beforeEnter(to, _from, next) {
							if (!store.getters.isAccountExists) {
								next("/user/setup-first-account");
							} else {
								next();
							}
						}
					},
					component: {
						render(c) {
							return c("router-view");
						}
					},
					children: [
						{
							path: "supplier",
							redirect: "supplier/",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "/",
									name: "user-supplier",
									meta: {
										requiresAuth: false,
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.SIDE_BAR.SUPPLIER")
										})
									},
									component: () => import("@/view/pages/user/supplier/SupplierListing.vue")
								},

								{
									path: "create",
									name: "user-supplier-create",
									meta: {
										requiresAuth: false,
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.SUPPLIER.NEW_SUPPLIER")
										})
									},
									component: () => import("@/view/pages/user/supplier/AddSupplier.vue")
								},
								{
									path: "edit/:id",
									name: "user-supplier-edit",
									meta: {
										requiresAuth: false,
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.SUPPLIER.EDIT_SUPPLIER")
										})
									},
									component: () => import("@/view/pages/user/supplier/EditSupplier.vue")
								},
								{
									path: "view/:id",
									name: "user-supplier-view",
									meta: {
										requiresAuth: false,
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.SUPPLIER.EXISTING_SUPPLIER_DETAIL")
										})
									},
									component: () => import("@/view/pages/user/supplier/EditSupplier.vue")
								}
							]
						},
						{
							path: "address",
							redirect: "address/",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "/",
									name: "user-address",
									meta: {
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.SIDE_BAR.DELIVERY_ADDRESS")
										})
									},
									component: () => import("@/view/pages/user/deliveryAddress/index.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else {
											next();
										}
									}
								},
								{
									path: "create",
									name: "user-address-create",
									meta: {
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.DELIVERY_ADDRESS.CREATE_NEW_ADDRESS_TITLE")
										})
									},
									component: () => import("@/view/pages/user/deliveryAddress/detail.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else {
											if (!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]) &&
												!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]["delivery_address"]) &&
												!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]["delivery_address"]["block_create"]) &&
												parseInt(store.getters["currentSystemSettings"]["delivery_address"]["block_create"]) == 0) {
												next();
											} else {
												next("user/address");
											}
										}
									}
								},
								{
									path: "edit/:id",
									name: "user-address-edit",
									meta: {
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.DELIVERY_ADDRESS.EDIT_ADDRESS_BOOK")
										})
									},
									component: () => import("@/view/pages/user/deliveryAddress/detail.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else {
											if (!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]) &&
												!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]["delivery_address"]) &&
												!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]["delivery_address"]["block_edit"]) &&
												parseInt(store.getters["currentSystemSettings"]["delivery_address"]["block_edit"]) == 0) {
												next();
											} else {
												next("user/address");
											}
										}
									}
								},
								{
									path: "view/:id",
									name: "user-address-view",
									meta: {
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.DELIVERY_ADDRESS.ADDRESS_BOOK_DETAIL")
										})
									},
									component: () => import("@/view/pages/user/deliveryAddress/detail.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else {
											next();
										}
									}
								}
							]
						},
						{
							path: "profile",
							redirect: "profile/",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "/",
									name: "user-profile",
									meta: {
										title: i18n.t("HEADER.QUICK_USER.QUICK_USER_TITLE")
									},
									component: () => import("@/view/pages/user/profile/UserProfile.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else {
											next();
										}
									}
								}
							]
						},
						{
							path: "change-password",
							redirect: "change-password/",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "/",
									name: "user-change-password",
									meta: {
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.PERSONAL_INFO.CHANGE_PASSWORD")
										})
									},
									component: () => import("@/view/pages/user/change_password/ChangePassword.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else {
											next();
										}
									}
								}
							]
						},
						{
							path: "account-management",
							redirect: "account-management/",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								// {
								// 	path: "/",
								// 	name: "account-management",
								// 	meta: {
								// 		title: i18n.t("COMMON.META_TITLE", {
								// 			page_title: i18n.t("CUSTOMER.SIDE_BAR.ACCOUNT_MANAGEMENT")
								// 		})
								// 	},
								// 	component: () => import("@/view/pages/user/account_management/AccountManagementListing.vue"),
								// 	beforeEnter(to, _from, next) {
								// 		if (!store.getters.isAccountExists) {
								// 			next("/user/setup-first-account");
								// 		} else {
								// 			next();
								// 		}
								// 	}
								// },
								// {
								// 	path: "create",
								// 	name: "account-management-create",
								// 	meta: {
								// 		title: i18n.t("CUSTOMER.ACCOUNT_MANAGEMENT.CREATE_NEW_ACCOUNT")
								// 	},
								// 	component: () => import("@/view/pages/user/account_management/detail.vue"),
								// 	beforeEnter(to, _from, next) {
								// 		if (!store.getters.isAccountExists) {
								// 			next("/user/setup-first-account");
								// 		} else {
								// 			next();
								// 		}
								// 	}
								// },
								{
									path: "edit/:id",
									name: "account-management-edit",
									meta: {
										title: i18n.t("CUSTOMER.ACCOUNT_MANAGEMENT.EDIT_ACCOUNT_MANAGEMENT")
									},
									component: () => import("@/view/pages/user/account_management/detail.vue"),
									beforeEnter(to, _from, next) {
										console.log(store.getters);
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else {
											if (!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]) &&
												!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]["customer"]) &&
												!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]["customer"]["block_edit_profile"]) &&
												parseInt(store.getters["currentSystemSettings"]["customer"]["block_edit_profile"]) == 0) {
												next();
											} else {
												next("user/profile");
											}

										}
									}
								},
								{
									path: "edit-request/:id",
									name: "edit-account-request",
									meta: {
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.ACCOUNT_MANAGEMENT.EDIT_ACCOUNT_MANAGEMENT")
										})
									},
									component: () => import("@/view/pages/user/account_management/detail.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else {
											if (!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]) &&
												!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]["customer"]) &&
												!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]["customer"]["block_edit_profile"]) &&
												parseInt(store.getters["currentSystemSettings"]["customer"]["block_edit_profile"]) == 0) {
												next();
											} else {
												next("user/profile");
											}
										}
									}
								},
								{
									path: "view-request/:id",
									name: "account-request-view",
									meta: {
										title: i18n.t("CUSTOMER.ACCOUNT_MANAGEMENT.ACCOUNT_MANAGEMENT_DETAILS")
									},
									component: () => import("@/view/pages/user/account_management/detail.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else {
											if (!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]) &&
												!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]["customer"]) &&
												!CoreSettingsJs.emptyObjectValidation(store.getters["currentSystemSettings"]["customer"]["block_edit_profile"]) &&
												parseInt(store.getters["currentSystemSettings"]["customer"]["block_edit_profile"]) == 0) {
												next();
											} else {
												next("user/profile");
											}
										}
									}
								},
								{
									path: "view/:id",
									name: "account-management-view",
									meta: {
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.ACCOUNT_MANAGEMENT.ACCOUNT_MANAGEMENT_DETAILS")
										})
									},
									component: () => import("@/view/pages/user/account_management/detail.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else {
											next();
										}
									}
								}
							]
						},
						{
							path: "role-management",
							redirect: "role-management/",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "/",
									name: "role-management",
									meta: {
										requiresAuth: true,
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.SIDE_BAR.ROLE_MANAGEMENT")
										})
									},
									component: () => import("@/view/pages/user/role_management/RoleManagementListing.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else if (!store.getters.AccessSubAccount) {
											Swal.fire({
												icon: "error",
												text: i18n.t("AUTH.GENERAL.MSG_NOT_ALLOW_ACCESS_MODULE", { module: i18n.t("CUSTOMER.SIDE_BAR.ROLE_MANAGEMENT") })
											});
											next("/dashboard")
										} else {
											next();
										}
									}
								},
								{
									path: "create",
									name: "role-management-create",
									meta: {
										requiresAuth: true,
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.ROLE_MANAGEMENT.CREATE_NEW_ROLE_TITLE")
										})
									},
									component: () => import("@/view/pages/user/role_management/AddRoleManagement.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else if (!store.getters.AccessSubAccount) {
											Swal.fire({
												icon: "error",
												text: i18n.t("AUTH.GENERAL.MSG_NOT_ALLOW_ACCESS_MODULE", { module: i18n.t("CUSTOMER.SIDE_BAR.ROLE_MANAGEMENT") })
											});
											next("/dashboard")
										} else if (!store.getters.isCurrentAccountMarkingExists) {
											Swal.fire({
												icon: "error",
												text: i18n.t("COMMON.MSG.NO_MARKING", { module_page: i18n.t("CUSTOMER.ROLE_MANAGEMENT.CREATE_NEW_ROLE_TITLE") })
											});
											next("/user/role-management")
										} else {
											next();
										}
									}
								},
								{
									path: "edit/:id",
									name: "role-management-edit",
									meta: {
										requiresAuth: true,
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.ROLE_MANAGEMENT.EDIT_ROLE_MANAGEMENT")
										})
									},
									component: () => import("@/view/pages/user/role_management/EditRoleManagement.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else if (!store.getters.AccessSubAccount) {
											Swal.fire({
												icon: "error",
												text: i18n.t("AUTH.GENERAL.MSG_NOT_ALLOW_ACCESS_MODULE", { module: i18n.t("CUSTOMER.SIDE_BAR.ROLE_MANAGEMENT") })
											});
											next("/dashboard")
										} else if (!store.getters.isCurrentAccountMarkingExists) {
											Swal.fire({
												icon: "error",
												text: i18n.t("COMMON.MSG.NO_MARKING", { module_page: i18n.t("CUSTOMER.ROLE_MANAGEMENT.EDIT_ROLE_MANAGEMENT") })
											});
											next("/user/role-management")
										} else {
											next();
										}
									}
								},
								{
									path: "view/:id",
									name: "role-management-view",
									meta: {
										requiresAuth: true,
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.ROLE_MANAGEMENT.ROLE_MANAGEMENT_DETAILS")
										})
									},
									component: () => import("@/view/pages/user/role_management/EditRoleManagement.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else if (!store.getters.AccessSubAccount) {
											Swal.fire({
												icon: "error",
												text: i18n.t("AUTH.GENERAL.MSG_NOT_ALLOW_ACCESS_MODULE", { module: i18n.t("CUSTOMER.SIDE_BAR.ROLE_MANAGEMENT") })
											});
											next("/dashboard")
										} else if (!store.getters.isCurrentAccountMarkingExists) {
											Swal.fire({
												icon: "error",
												text: i18n.t("COMMON.MSG.NO_MARKING", { module_page: i18n.t("CUSTOMER.ROLE_MANAGEMENT.ROLE_MANAGEMENT_DETAILS") })
											});
											next("/user/role-management")
										} else {
											next();
										}
									}
								}
							]
						},
						{
							path: "data-filter",
							redirect: "data-filter/",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "/",
									name: "data-filter",
									meta: {
										requiresAuth: true,
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.SIDE_BAR.DATA_FILTER")
										})
									},
									component: () => import("@/view/pages/user/data_filter/CustomerDataFilterListing.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else if (!store.getters.AccessSubAccount) {
											Swal.fire({
												icon: "error",
												text: i18n.t("AUTH.GENERAL.MSG_NOT_ALLOW_ACCESS_MODULE", { module: i18n.t("CUSTOMER.SIDE_BAR.DATA_FILTER") })
											});
											next("/dashboard")
										} else {
											next();
										}
									}
								},
								{
									path: "create",
									name: "data-filter-create",
									meta: {
										requiresAuth: true,
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.DATA_FILTER.CREATE_NEW_TITLE")
										})
									},
									component: () => import("@/view/pages/user/data_filter/AddCustomerDataFilter.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else if (!store.getters.AccessSubAccount) {
											Swal.fire({
												icon: "error",
												text: i18n.t("AUTH.GENERAL.MSG_NOT_ALLOW_ACCESS_MODULE", { module: i18n.t("CUSTOMER.SIDE_BAR.DATA_FILTER") })
											});
											next("/dashboard")
										} else if (!store.getters.isCurrentAccountMarkingExists) {
											Swal.fire({
												icon: "error",
												text: i18n.t("COMMON.MSG.NO_MARKING", { module_page: i18n.t("CUSTOMER.DATA_FILTER.CREATE_NEW_TITLE") })
											});
											next("/user/data-filter")
										} else {
											next();
										}
									}
								},
								{
									path: "edit/:id",
									name: "data-filter-edit",
									meta: {
										requiresAuth: true,
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.DATA_FILTER.EDIT")
										})
									},
									component: () => import("@/view/pages/user/data_filter/EditCustomerDataFilter.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else if (!store.getters.AccessSubAccount) {
											Swal.fire({
												icon: "error",
												text: i18n.t("AUTH.GENERAL.MSG_NOT_ALLOW_ACCESS_MODULE", { module: i18n.t("CUSTOMER.SIDE_BAR.DATA_FILTER") })
											});
											next("/dashboard")
										} else if (!store.getters.isCurrentAccountMarkingExists) {
											Swal.fire({
												icon: "error",
												text: i18n.t("COMMON.MSG.NO_MARKING", { module_page: i18n.t("CUSTOMER.DATA_FILTER.EDIT") })
											});
											next("/user/data-filter")
										} else {
											next();
										}
									}
								},
								{
									path: "view/:id",
									name: "data-filter-view",
									meta: {
										requiresAuth: true,
										title: i18n.t("COMMON.META_TITLE", {
											page_title: i18n.t("CUSTOMER.DATA_FILTER.DETAILS")
										})
									},
									component: () => import("@/view/pages/user/data_filter/EditCustomerDataFilter.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else if (!store.getters.AccessSubAccount) {
											Swal.fire({
												icon: "error",
												text: i18n.t("AUTH.GENERAL.MSG_NOT_ALLOW_ACCESS_MODULE", { module: i18n.t("CUSTOMER.SIDE_BAR.DATA_FILTER") })
											});
											next("/dashboard")
										} else if (!store.getters.isCurrentAccountMarkingExists) {
											Swal.fire({
												icon: "error",
												text: i18n.t("COMMON.MSG.NO_MARKING", { module_page: i18n.t("CUSTOMER.DATA_FILTER.DETAILS") })
											});
											next("/user/data-filter")
										} else {
											next();
										}
									}
								}
							]
						},
						{
							path: "setup-first-account",
							name: "setup-first-account",
							component: () => import("@/view/pages/user/account_management/detail.vue"),
							meta: {
								title: i18n.t("CUSTOMER.ACCOUNT_MANAGEMENT.SETUP_ACCOUNT")
							},
							beforeEnter(to, _from, next) {
								if (store.getters.isAccountExists) {
									next("/home");
								} else {
									next();
								}
							}
						},
						{
							path: "marking",
							redirect: "marking/",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "/",
									name: "customer-marking",
									meta: {
										title: i18n.t("COMMON.LBL_CUSTOMER_MARKING")
									},
									component: () => import("@/view/pages/user/marking/CustomerMarking.vue"),
									beforeEnter(to, _from, next) {
										if (!store.getters.isAccountExists) {
											next("/user/setup-first-account");
										} else if (!store.getters.isCurrentAccountMarkingExists) {
											Swal.fire({
												icon: "error",
												text: i18n.t("COMMON.MSG.NO_MARKING", { module_page: i18n.t("COMMON.LBL_CUSTOMER_MARKING") })
											});
											next("/dashboard")
										} else {
											next();
										}
									}
								}
							]
						}
					]
				},
				{
					path: "warehouse",
					redirect: "warehouse/index",
					component: {
						render(c) {
							return c("router-view");
						}
					},
					children: [
						{
							path: "index",
							name: "warehouse-listing",
							meta: {
								requiresAuth: false,
								title: i18n.t("COMMON.META_TITLE", {
									page_title: i18n.t("CUSTOMER.SIDE_BAR.WAREHOUSE_ADDRESS")
								})
							},
							component: () => import("@/view/pages/warehouse/index.vue")
						}
					]
				},
				{
					path: "sub-account",
					name: "sub-account",
					redirect: "sub-account/listing",
					component: {
						render(c) {
							return c("router-view");
						}
					},
					children: [
						{
							path: "listing",
							name: "sub-account-listing",
							meta: {
								requiresAuth: true,
								title: i18n.t("CUSTOMER.SUB_ACCOUNT_MANAGEMENT.SUB_ACCOUNT_MANAGEMENT_TITLE")
							},
							component: () => import("@/view/pages/sub_account/SubAccountListing.vue"),
							beforeEnter(to, _from, next) {
								if (!store.getters.isAccountExists) {
									next("/user/setup-first-account");
								} else if (!store.getters.AccessSubAccount) {
									Swal.fire({
										icon: "error",
										text: i18n.t("AUTH.GENERAL.MSG_NOT_ALLOW_ACCESS_MODULE", { module: i18n.t("CUSTOMER.SIDE_BAR.SUB_ACCOUNT") })
									});
									next("/dashboard")
								} else {
									next();
								}
							}
						},
						{
							path: "create",
							name: "create-sub-account",
							meta: {
								requiresAuth: true,
								title: i18n.t("CUSTOMER.SUB_ACCOUNT_MANAGEMENT.CREATE_NEW_SUB_ACCOUNT")
							},
							component: () => import("@/view/pages/sub_account/CreateSubAccount.vue"),
							beforeEnter(to, _from, next) {
								if (!store.getters.isAccountExists) {
									next("/user/setup-first-account");
								} else if (!store.getters.AccessSubAccount) {
									Swal.fire({
										icon: "error",
										text: i18n.t("AUTH.GENERAL.MSG_NOT_ALLOW_ACCESS_MODULE", { module: i18n.t("CUSTOMER.SIDE_BAR.SUB_ACCOUNT") })
									});
									next("/dashboard")
								} else if (!store.getters.isCurrentAccountMarkingExists) {
									Swal.fire({
										icon: "error",
										text: i18n.t("COMMON.MSG.NO_MARKING", { module_page: i18n.t("CUSTOMER.SUB_ACCOUNT_MANAGEMENT.CREATE_NEW_SUB_ACCOUNT") })
									});
									next("/sub-account/listing")
								} else {
									next();
								}
							}
						},
						{
							path: "detail/:id",
							name: "view-sub-account",
							meta: {
								requiresAuth: true,
								title: i18n.t("CUSTOMER.SUB_ACCOUNT_MANAGEMENT.VIEW_SUB_ACCOUNT")
							},
							component: () => import("@/view/pages/sub_account/EditSubAccount.vue"),
							beforeEnter(to, _from, next) {
								if (!store.getters.isAccountExists) {
									next("/user/setup-first-account");
								} else if (!store.getters.AccessSubAccount) {
									Swal.fire({
										icon: "error",
										text: i18n.t("AUTH.GENERAL.MSG_NOT_ALLOW_ACCESS_MODULE", { module: i18n.t("CUSTOMER.SIDE_BAR.SUB_ACCOUNT") })
									});
									next("/dashboard")
								} else if (!store.getters.isCurrentAccountMarkingExists) {
									Swal.fire({
										icon: "error",
										text: i18n.t("COMMON.MSG.NO_MARKING", { module_page: i18n.t("CUSTOMER.SUB_ACCOUNT_MANAGEMENT.VIEW_SUB_ACCOUNT") })
									});
									next("/sub-account/listing")
								} else {
									next();
								}
							}
						},
						{
							path: "edit/:id",
							name: "edit-sub-account",
							meta: {
								requiresAuth: true,
								title: i18n.t("COMMON.META_TITLE", {
									page_title: i18n.t("CUSTOMER.SUB_ACCOUNT_MANAGEMENT.EDIT_SUB_ACCOUNT")
								})
							},
							component: () => import("@/view/pages/sub_account/EditSubAccount.vue"),
							beforeEnter(to, _from, next) {
								if (!store.getters.isAccountExists) {
									next("/user/setup-first-account");
								} else if (!store.getters.AccessSubAccount) {
									Swal.fire({
										icon: "error",
										text: i18n.t("AUTH.GENERAL.MSG_NOT_ALLOW_ACCESS_MODULE", { module: i18n.t("CUSTOMER.SIDE_BAR.SUB_ACCOUNT") })
									});
									next("/dashboard")
								} else if (!store.getters.isCurrentAccountMarkingExists) {
									Swal.fire({
										icon: "error",
										text: i18n.t("COMMON.MSG.NO_MARKING", { module_page: i18n.t("CUSTOMER.SUB_ACCOUNT_MANAGEMENT.EDIT_SUB_ACCOUNT") })
									});
									next("/sub-account/listing")
								} else {
									next();
								}
							}
						}
					]
				}
			]
		},
		{
			path: "/login",
			meta: {
				requiresAuth: false
			},
			props: true,
			component: () => import("@/view/pages/auth/login_pages/Login"),
			children: [
				{
					path: "/",
					name: "login",
					props: true,
					meta: {
						requiresAuth: false
					},
					component: () => import("@/view/pages/auth/login_pages/Login")
				}
			]
		},
		{
			path: "/register",
			meta: {
				requiresAuth: false
			},
			component: {
				render(c) {
					return c("router-view");
				}
			},
			children: [
				{
					path: "/",
					meta: {
						requiresAuth: false,
						title: i18n.t("AUTH.REGISTER.TITLE")
					},
					component: () => import("@/view/pages/auth/login_pages/Register")
				},
				{
					path: "/register/:smid",
					meta: {
						requiresAuth: false,
						title: i18n.t("AUTH.REGISTER.TITLE")
					},
					component: () => import("@/view/pages/auth/login_pages/Register")
				}
			]
		},
		{
			name: "verification",
			path: "/verification/:vtoken",
			meta: {
				requiresAuth: false
			},
			component: () => import("@/view/pages/auth/login_pages/EmailVerification")
		},
		{
			name: "forgot-password",
			path: "/forgot-password/:vtoken",
			meta: {
				requiresAuth: false
			},
			component: () => import("@/view/pages/auth/login_pages/ForgotPassword")
		},
		{
			// the 404 route, when none of the above matches
			path: "/404",
			name: "404",
			meta: {
				requiresAuth: false
			},
			component: () => import("@/view/pages/error/Error-1.vue")
		},
		{
			path: "*",
			meta: {
				requiresAuth: false
			},
			beforeEnter(to, _from, next) {
				var cms_path = "";
				// let cms_title = "";
				// let cms_content = "";
				var url_full_path = "";
				if (to.fullPath) {
					try {
						if (to.fullPath.substring(0, 2) == "//") {
							url_full_path = new URL(to.fullPath.substring(2));
						} else {
							url_full_path = new URL(to.fullPath);
						}
					} catch (_) {
						url_full_path = new URL(window.location.origin + to.fullPath);
					}

					if (url_full_path.origin != window.location.origin) {
						window.location.href = url_full_path;
					} else {
						if (to.fullPath.substring(0, 1) == "/") {
							cms_path = to.fullPath.substring(1);
						}
						//do the api check and load content

						ApiService.post("cms/page/content", { slug: cms_path, language_id: 1 }, true)
							.then((response) => {
								if (response.status == "200" && Object.keys(response.data.result).length > 0) {
									// cms_title = response.data.result.title;
									// cms_content = response.data.result.body;

									next({
										name: "cms-page",
										params: {
											slug: cms_path
										}
									});
								} else {
									next("/404");
								}
							})
							.catch(() => {
								next("/404");
							});
					}
				}
			}
		}
	]
});
