// USA
export const locale = {
	TRANSLATOR: {
		SELECT: "Select your language"
	},
	MENU: {
		DASHBOARD: "Dashboard",
		INQUIRY_QUOTATION: "Inquiry Quotation"
	},
	AUTH: {
		GENERAL: {
			OR: "Or",
			SUBMIT_BUTTON: "Submit",
			NO_ACCOUNT: "Don't have an account?",
			SIGNUP_BUTTON: "Sign Up",
			SIGNIN_BUTTON: "Sign In",
			FORGOT_BUTTON: "Forgot Password ?",
			BACK_BUTTON: "Back",
			PRIVACY: "Privacy",
			LEGAL: "Legal",
			CONTACT: "Contact",
			CANCEL_BUTTON: "Cancel",
			TERMS: "Terms",
			PLANS: "Plans",
			CONTACT_US: "Contact Us",
			SUB_ACCOUNT: "Sub-Account",
			SWITCH_LOGIN_TYPE_TO_MAIN_MESSAGE: "Using main account (email)?",
			SWITCH_LOGIN_TYPE_TO_SUB_MESSAGE: "Using sub-account (ID)? ",
			MSG_NOT_ALLOW_ENTER_EDIT_PAGE: "Your account {status}. Not allow to do any transaction. Please assist customer service.",
			MSG_NOT_ALLOW_ACCESS_MODULE: "Not allow to access {module}"
		},
		LOGIN: {
			TITLE: "Login",
			TITLE_SUB_ACCOUNT: "Login",
			MESSAGE: "New Here?",
			CREATE: "Create Account",
			LOGIN_REQUIRED: "Login Session Timeout",
			SESSION_TIMEOUT_MSG: "Session has expired, Please login again. <br /> <strong>Note: </strong> Currently page will be refresh.",
			MSG_BAD_CREDENTIALS: "Invalid Username or Password",
			MSG_ACCOUNT_NOT_VERIFIED: "Your account is not verified yet.",
			MSG_ACCOUNT_NOT_VERIFIED_DIALOG:
				"Account is not verified yet.<br /> If you not received email, click on below to have an new email to your register email address.",
			RESET_SUB_ACCOUNT_ONE_TIME_PASSWORD_TITLE: "Reset Sub-Account Password",
			RESET_SUB_ACCOUNT_ONE_TIME_PASSWORD_DESC: "Please change your password to a new one for security purposes.",
			RESET_SUB_ACCOUNT_SUCCESS_MESSAGE: "Successfully reset sub-account password. Please relogin with the new password.",
			RESET_MAIN_ACCOUNT_PASSWORD: "Reset Password",
			PASS_EXPIRED: "Password Expired",
			PASS_EXPIRED_DESC: "Your password has expired. Please choose an option:",
			PASS_SET_NEW_PASS_BTN: "Set New Password",
			PASS_KEEP_PASS_BTN: "Keep Current Password",
		},
		FORGOT: {
			TITLE: "Forgot Password?",
			DESC: "Enter your email to reset your password",
			SUCCESS: "Your account has been successfully reset.",
			TITLE_SUB_ACCOUNT: "Forgot Your Sub-Account Password?",
			DESC_SUB_ACCOUNT: "Please contact your privileged admin or main account holder to carry out password reset procedure.",
			RESET_PASSWORD_TITLE: "Reset Password",
			FAILED: "Forgot email password failed to send out",
			MSG_FORGOT_PASSWORD_LINK_SEND: "Forgot Password Link has been sent to your email."
		},
		REGISTER: {
			TITLE: "Sign Up",
			SETUP_ACCOUNT: "Set Up Account",
			DESC: "Enter your details to create your account",
			RADIO_INDIVIDUAL: "Individual",
			RADIO_COMPANY: "Company",
			TANDCDESC: "I Agree the",
			TANDC: "Terms and Conditions",
			ERROR: "Please provide correct data!",
			SUCCESS: "Your account has been successfully registered.",

			TERMS_CONDITION: 'By clicking this you agree with our <a @click="view_tnc()">Terms & Condition</a>',
			PASSWORD: {
				PASSWORD_LENGTH: "Password must be 8 to 12 character",
				CONTAIN_AT_LEAST_LOWER_CASE: "At Least 1 Lower Case",
				CONTAIN_AT_LEAST_UPPER_CASE: "At Least 1 Upper Case",
				CONTAIN_AT_LEAST_ONE_DIGIT: "At Least 1 digit",
				CONTAIN_AT_LEAST_SPECIAL_CHARACTER: "At Least 1 special character(#?!@$%^&*-)",
				PASSWORD_REQUIREDMENT_TITLE: "Password Requirement: "
			},
			MSG_EMAIL_UNIQUE: "The email has been registered",
			MSG_SUCCESSFUL_REGISTER: "Register successfully. Please check your email for verification email."
		},
		INPUT: {
			NAME: "Name",
			NICKNAME: "Nickname",
			COMPANY_NAME: "Company Name",
			ID: "NRIC/Passport",
			COMPANY_ID: "Business Register Number",
			CONTACT: "Contact Number",
			EMAIL: "Email",
			SUB_ACCOUNT_ID: "Sub-Account ID",
			SUB_ACCOUNT_ID_PREFIX: "Prefix",
			PASSWORD: "Password",
			CONFIRM_PASSWORD: "Confirm Password",
			PHOTO: "Upload your NRIC Photo...",
			COMPANY_PHOTO: "Upload your SSM Photo...",
			DROP_PHOTO: "Drop photo here...",
			USERNAME: "Username"
		},
		EMAIL_VERIFICATION: {
			TITLE: "Email Verification",
			SUCCESSFUL_VERIFY_TITLE: "Verified",
			SUCCESSFUL_SENT_EMAIL_TITLE: "An Email Verification link has been sent. ",
			SUCCESSFUL_VERIFY: "You have successfully verified account. Click on below, continue Login",
			FAILED_VERIFY_TITLE: "Failed",
			EMAIL_EXPIRED_TITLE: "Email verification Link Expired",
			EMAIL_VERIFIED_TITLE: "Email verification Link verified",
			INVALID_TOKEN: "Invalid Token",
			TOKEN_EXPIRE:
				"The Email verification link was expired.<br /> Click on below to have an new email to your register email address. <br/><strong>Please note this link will expired within {hour} hour</strong>",
			TOKEN_VERIFIED:
				"The Email verification link was verified.",
			EMAIL_LABEL: "Please Enter your email address.<br/><strong>Please note this link will expired within {hour} hour</strong>",
			PH_ENTER_EMAIL: "Enter Email",
			RESEND_EMAIL_BUTTON: "Send Verification Email",
			RESEND_CANCEL_BUTTON: "Back To Login",
			SUCCESSFUL_SENT_EMAIL_MESSAGE:
				"Please click on the link has been sent to your email to verify your registered email and completed the registration process<br /><strong> Please note this link will expired with {hour} hour.</strong>"
		},
		VALIDATION: {
			INVALID: "{name} is not valid",
			REQUIRED: "{name} is required",
			MIN_LENGTH: "{{name}} minimum length is {{min}}",
			AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
			NOT_FOUND: "The requested {{name}} is not found",
			INVALID_LOGIN: "The login detail is incorrect",
			REQUIRED_FIELD: "Required field",
			MIN_LENGTH_FIELD: "Minimum field length:",
			MAX_LENGTH_FIELD: "Maximum field length:",
			INVALID_FIELD: "Field is not valid",
			PASSWORD_MISMATCH: "The password and its confirm are not the same",
			PASSWORD_TOO_WEAK: "The password is too weak",
			PASSWORD_DOESNT_MEET_COMPLEXITY_REQUIREMENTS: "The password doesn't meet the complexity requirements"
		}
	},
	DASHBOARD: {
		NOTICE: {
			BUTTON: "Take a look !"
		},
		SLIDER: {
			FIRST_SLIDE_DESC: "1st slide",
			SECOND_SLIDE_DESC: "2nd slide",
			THIRD_SLIDE_DESC: "3rd slide"
		},
		CONTENT: {}
	},
	COMMON: {
		TITLE_LISTING: "{name} Listing",
		TITLE_LISTING_DESC: "Listing of the {name}",
		TITLE_CREATE: "Create {name}",
		TITLE_CREATE_DESC: "Create new {name}",
		TITLE_UPDATE: "Edit {name}",
		TITLE_UPDATE_DESC: "Update existing {name}",
		TITLE_VIEW: "View {name}",
		TITLE_VIEW_DESC: "{name} Details",
		META_TITLE: "{page_title}",
		TITLE_PREVIEW_IMAGE: "Preview Image",
		TITLE_UPLOAD_IMAGE: "Upload Image",
		TITLE_PREVIEW_DOC: "Preview Supported Document",
		TITLE_UPLOAD_DOCUMENT: "Upload Supported Document",
		TITLE_TERMS_CONDITION: "Terms & Condition",

		LBL_WAREHOUSE_TYPE: "Warehouse Type",
		LBL_WAREHOUSE: "Warehouse",
		LBL_CUSTOMER_MARKING: "Customer Marking",

		SELECTION_OPTION_TITLE: "-- Select One --",
		SELECTION_CREATE_NEW_TITLE: "Create New",

		SELECTION_ALL_TITLE: "All",
		BTN_SEARCH: "Search",
		API_SERVICE_ERROR: "System Error, Please contact System Admin.",

		COL_ACTION: "Action",
		COL_STATUS: "Status",
		COL_MARKING: "Marking",
		COL_WAREHOUSE: "Warehouse",
		BTN_CLOSE: "Close",
		BTN_PRINT: "Print",
		BTN_SUBMIT: "Submit",
		BTN_SAVE_AS_DRAFT: "Save as Draft",
		BTN_CANCEL: "Cancel",
		BTN_CREATE: "Create",
		BTN_EDIT: "Edit",
		BTN_COPY: "Copy",
		BTN_UPLOAD: "Click or drag file to this area to upload",
		BTN_VIEW: "View",
		BTN_DELETE: "Delete",
		BTN_INACTIVE: "Inactive",
		BTN_CONFIRM_DELETE: "Yes, delete it!",
		BTN_CANCEL_DELETE: "No, cancel!",
		BTN_PREVIEW_IMAGE: "Preview Image",
		BTN_PREVIEW_DOC: "Preview Document",
		BTN_UPLOAD_IMAGE: "Upload Image",
		BTN_UPLOAD_DOC: "Upload Supported Document",
		BTN_CONFIRM: "Confirm",
		BTN_SAVE: "Save",
		BTN_BACK: "Back",

		LBL_STATUS: "Status",
		ACTIVE_STATUS_VAL: "Active",
		INACTIVE_STATUS_VAL: "Inactive",
		PENDING_STATUS_VAL: "Pending",
		APPROVED_STATUS_VAL: "Approved",
		REJECTED_STATUS_VAL: "Rejected",
		VERIFYING_STATUS_VAL: "Verifying",
		SUSPEND_STATUS_VAL: "Suspend",
		REQUIRED_TITLE: '<span class="text-danger font-size-h4">*</span><span> is required</span>',
		AUTO_GENERATE: "Auto Generate",
		TXT_ALL: "All",
		VALIDATION: {
			INVALID: "{name} is not valid",
			REQUIRED: "{name} is required",
			EXISTS: "{name} exists",
			MIN_LENGTH: "{{name}} minimum length is {{min}}",
			AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
			NOT_FOUND: "The requested {{name}} is not found",
			REQUIRED_FIELD: "Required field",
			MIN_LENGTH_FIELD: "Minimum field length:",
			MAX_LENGTH_FIELD: "Maximum field length:",
			INVALID_FIELD: "Field is not valid",
			TNC: "Accepting terms & conditions are required"
		},
		PH_ENTER_CURRENCY: "Enter Currency Code/Symbol",
		MSG_SYSTEM_ERROR: "System Error",
		SELECTION_OTHERS_TITLE: "Other",
		MSG_WARNING_TITLE: "Warning !",
		MGS_BLOCK_PREVIEW_IMAGE: "Image Size too small, not allow to preview",
		MSG_IMG_SUPPORTED: "*Support JPG,JPEG,PNG,GIF file, file size not more that 500kb.",
		MSG_IMG_ERROR_SIZE: "The size of file should be less than 1 MB",

		MSG: {
			MSG_ERROR_TITLE: "Oops...",
			NOT_FOUND: "{name} not found",
			API_SERVICE_ERROR: "System Error, Please assist System Admin.",
			SUCCESSFUL_SAVE: "Successful save record",
			ARE_YOU_SURE: "Are You Sure?",
			MSG_UNABLE_UPLOAD_IMAGE: "Unable upload image. Please try again",
			MSG_BEFORE_LEAVE_PAGE: "Changes you made may not saved. If you leave page, these changes will be lost.",
			MSG_BEFORE_LEAVE_PAGE_TITLE: "Are you sure you want to leave this page?",
			MSG_CODE_EXIST: "Code exists in the system.",
			MSG_DATA_NOT_FOUND: "No Records Found",
			COMPLETE_SYNC_ALL: "Completed to sync all record",
			WARNING_CANNOT_REVERT: "Deleted/Inactived record won't be able to revert",
			SUCCESSFULL_DELETE: "Record was deleted.",
			SUCCESS_INACTIVE: "Record was inactived",
			SUCCESS_TO_DELETE: "{mod} record successful to delete",
			MSG_NOT_ALLOW_DELETE: "Ooops, You are not allow delete this {mod} record",

			IMAGINARY_FILE_SAFE_MSG: "Your imaginary file is safe :)",
			SUCCESSFUL_DELETE_MSG: "Your file has been deleted.",
			NO_MARKING: "Marking not yet ready. You are not allow to access {module_page} page. Please contact Customer Service"
		},

		TABLE_EMPTY_MSG: "Data Not Available",
		MSG_SUCCESS_TITLE: "Success",
		ADDRESS: {
			LBL_ADDRESS: "Address",
			LBL_COUNTRY: "Country",
			LBL_STATE: "State",
			LBL_CITY: "City",
			LBL_POSTCODE: "Postcode",
			LBL_BUILDING_NAME: "Building Name",
			LBL_EMAIL: "Email",
			LBL_UPSTAIRS: "Move Upstairs"
		},
		POPULAR_TITLE: "Popular",
		MSG_PERSONAL_DATA_INCOMPLETE: "Personal Data Incomplete. Please complete first",
		MSG_DATA_EXIST: "Reminder: Data exists in this record - {record}",
		MSG_DATA_EXIST_SECOND_LINE: "The system will not allow you to save the same data record twice.",
		MODIFIED_BY: "Modified By",
		SAVE_AS_DRAFT_STATUS_VAL: "Save As Draft",
		LBL_TEMRS_COND: "By clicking this you agree with our ",
		STATUS: {
			INACTIVE: "Inactive",
			ACTIVE: "Active",
			SUSPEND: "Suspend",
			YES: "Yes",
			NO: "No",
			SAVE_AS_DRAFT: "Save As Draft"
		},
		PH_AUTO_DOC_NO: "[Auto]"
	},
	QUOTATION_MANAGEMENT: {
		INQUIRY_QUOTATION: {
			INQUIRY_QUOTATION_TITLE: "Inquiry Quotation",
			REQUEST_FOR_QUOTE_TITLE: "Request for Quote",
			INQUIRY_QUOTATION_SUMMARY: "Inquiry Quotation Detail",
			INQUIRY_QUOTATION_CUSTOMER_TITLE: "Quotation Header",
			PRODUCT_DETAILS_TITLE: "Cargo Detail",
			ORIGIN_DESTIONATION_TITLE: "Origin & Destination Detail",
			PRODUCT_DETAILS_QUOTE_PRICE_TITLE: "Cargo Detail & Quote Price",
			QUOTE_PRICE_TITLE: "Quote Price",
			EXCEPTION_CHARGES_TITLE: "Side Charges",
			EXCEPTION_CHARGES_WEIGHT_TITLE: "Weight/M3",
			EXCEPTION_CHARGES_DIMESION_TITLE: "Dimension",
			EXCEPTION_CHARGES_CHARGES_TITLE: "Charges",
			PRODUCT_QUOTE_DESC: "Type of Goods",
			SERVICE_CHARGES: "Service Charges",
			PRODUCT_IMAGE: "Product Images",
			SUPPORTED_DOCUMENT: "Supported Documents",
			ORIGIN_COUNTRY: "Origin Country",
			DESTINATION_ZONE_TITLE: "Delivery Zone",
			INQUIRY_PROCESS_STATUS: "Processing",

			COL_INQUIRY_NO: "Inquiry No",
			COL_INQUIRY_TIME: "Inquiry On",
			COL_QUOTATION_NO: "Quotation No",
			COL_SHIPMENT_TYPE: "Shipt. Type",
			COL_SHIPMENT_MODE: "Shipt. Mode",
			COL_WAREHOUSE: "Warehouse",
			COL_SUPPLIER: "Supplier",
			COL_COUNTRY: "From/To Country",
			COL_STATUS: "Status",
			COL_EFFECTIVE_ON: "Effective On",
			COL_PRODUCT_NAME: "Commodity",
			COL_REF_TYPE: "Container",
			COL_MARKING: "Customer Marking",
			COL_DESTINATION_ZONE: "Delivery Zone",

			LBL_INQUIRY_NO: "Inquiry No",
			LBL_INQUIRY_DATE: "Inquiry Date",
			LBL_QUOTATION_DATE: "Quotation Date",
			LBL_DATE_FROM: "Date From",
			LBL_DATE_TO: "Date To",
			LBL_INQUIRY_ON: "Inquiry On",
			LBL_QUOTATION_NO: "Quotation No",
			LBL_ISSUED_NO: "Issued On",
			LBL_EXPIRED_ON: "Expired On",
			LBL_EFFECTIVE_ON: "Effective On",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_MOVEMENT_TYPE: "Movement Type",
			LBL_SHIPMENT_TERMS: "Shipment Terms",
			LBL_ORIGIN_COUNTRY: "Origin Country",
			LBL_DEST_COUNTRY: "Destination Country",
			LBL_MARKING: "Customer Marking",
			LBL_QUOTATION_STATUS: "Quotation Status",
			LBL_PIC: "Person In Charge",
			LBL_EMAIL: "Email",
			LBL_CONTACT_NO: "Mobile No.",
			LBL_CONTACT_NO_ALT: "Mobile No.(Alt).",
			LBL_OFFICE_CONTACT_NO: "Office Contact No.",
			LBL_FAX_CONTACT_NO: "Fax No.",
			LBL_CUSTOMER_NAME: "Customer Name",
			LBL_BILL_ADDRESS: "Bill Address",
			LBL_SUPPLIER_COMMENT: "Supplier Comment",
			LBL_REJECTED_REASON: "Rejected Reason",
			LBL_WAREHOUSE: "Warehouse",
			LBL_CUSTOMER_MARKING: "Customer Marking",
			EXPIRED_ON_TITLE: "Expired On",
			DRAFT_STATUS: "Draft",
			ORIGIN_ADDRESS_TITLE: "Origin Warehouse/City",
			DESTINATION_ADDRESS_TITLE: "Destination Address",
			MSG_BLOCK_DELETE_QUOTATION: "Sorry, this quotation not in {quote_status} status. You are not allowed delete.",
			MSG_SUCCESS_DELETE: "This {inquiry_no} inquiry quotation successful cancel.",
			MSG_CONFIRM_REVERT_REJECT: "Are you sure to revert this {quotation_no} quotation?",
			MSG_SUCCESS_REVERT: "You has been successful reverted this {quotation_no} quotation.",
			MSG_PRODUCT_IMAGE_NO_UPLOAD: "No product images has been uploaded",
			MSG_SUPPORTED_DOCUMENT_NO_UPLOAD: "No Document has been uploaded",
			MSG_CHARGES_EXPIRED: "Quotation No.: {quotation_no} part of charges expired. Please assist customer service to revise quotation.",
			NO_ALLOW_REVERT_REJECT_QUOTATION: "Invalid Quotation, you are not allow revert.",

			BTN_COPY: "Copy",
			BTN_VIEW: "View",
			BTN_DELETE: "Delete",
			BTN_ACKNOWLEDGE: "Acknowledge",
			BTN_PREVIEW_QUOTATION: "Preview Quotation",
			BTN_REJECT: "Reject",
			BTN_REJECT_N_COPY: "Reject & Copy",
			BTN_REVERT_CANCEL: "Revert Reject",
			BTN_PHOTO: "Photo",
			BTN_SUPPORTED_DOCUMENT: "Supported Document",
			BTN_PRINT_QUOTATION: "Print Quotation",
			BTN_ACKNOWLEDGE_QUOTATION: "Acknowledge Quotation",
			BTN_REVERT_QUOTATION: "Revert Reject Quotation",
			BTN_VIEW_HISTORY: "History"
		},
		REQUEST_FOR_QUOTE: {
			FROM_WAREHOUSE_TITLE: "Origin Warehouse/Country",
			WAY_TO_SHIP_TITLE: "How would you like to ship?",
			SUPPLIER_DETAILS_TITLE: "Supplier/Forwarder",
			LOADING_PORT_TITLE: "Loading Port",
			AGENT_FORWARDER_TITLE: "Forwarder/Agent Details",
			DELIVERY_ADDRESS_TITLE: "Destination",
			DESTINATION_PORT_TITLE: "Destination Port",
			SHIPMENT_DETAILS_TITLE: "Cargo Details",
			SPECIAL_REQUEST_TITLE: "Special Request",
			MAX_PRODUCT_CHARACTER: "Max 2000 Characters",
			SHIPMENT_ORIGIN_TITLE: "Shipment & Origin",

			LBL_COMPANY_NAME: "Company Name",
			LBL_PIC: "Person In Charge",
			LBL_EMAIL_ADDRESS: "Email Address",
			LBL_CONTACT_NUMBER: "Mobile Contact No.",
			LBL_BILL_ADDRESS: "Bill Address",
			LBL_OFFICE_CONTACT: "Office Contact No.",
			LBL_OFFICE_FAX_CONTACT: "Office Fax No.",
			LBL_MARKING: "Customer Marking",
			LBL_WAREHOUSE_TYPE: "Warehouse Type",
			LBL_WAREHOUSE: "Warehouse",
			LCL_DELIVEYR_ZONE: "Delivery Zone",
			LCL_SPECIAL_ZONE: "Special Zone",
			LBL_DESTINATION_COUNTRY: "Destination Country",

			WAREHOUSE_SELECTION_OTHERS: "Others",

			PH_SPECIAL_REQUEST: "Write a Message...",

			SHIPPING: {
				LBL_TYPE: "Shipment Type",
				LBL_MODE: "Shipment Mode",
				LBL_MOVEMENT: "Movement Type",
				LBL_TERM: "Shipment Terms"
			},

			SUPPLIER: {
				LBL_NAME: "Supplier/Forwarder Name",
				LBL_ADDRESS: "Address",
				LBL_POSTCODE: "Postal Code",
				LBL_CITY: "City",
				LBL_STATE: "State",
				LBL_CONTACT_NUMBER: "Contact No.",
				LBL_CONTACT_NUMBER_PIC: "Person In Charges",
				LBL_CONTACT_NUMBER_CALLING_CODE: "Supplier/Forwarder Calling Code"
			},

			FORWARDER: {
				LBL_COMPANY: "Forwarder Company Name",
				LBL_PIC: "Forwarder Person In Charge",
				LBL_CONTACT_NUMBER: "Forwarder Contact Number",
				LBL_CONTACT_NUMBER_CALLING_CODE: "Forwarder Contact Calling Code"
			},

			DELIVERY: {
				LBL_ADDRESS_NAME: "Destination Address",
				LBL_ADDRESS: "Address",
				LBL_BUILDING_NAME: "Building Name",
				LBL_UPSTAIRS: "upstairs",
				LBL_COUNTRY: "Country",
				LBL_STATE: "State",
				LBL_CITY: "City",
				LBL_POSTCODE: "Postal Code",
				LBL_BRN: "Business Register Number"
			},

			DETAILS: {
				LBL_PRODUCT_CATEGORY: "Commodity Description",
				LBL_BRAND_DESCRIPTION: "Brand Description",
				LBL_REF_TYPE: "Container Type",
				LBL_PARCEL_LOST_WARRANTY: "Parcel Lost Guarantee",
				LBL_SPECIAL_MATERIAL: "Special Material",
				LBL_GOODS_AMOUNT: "Amount",
				LBL_ESTIMATED_DIMENSION: "Total Dimension (M3)",
				LBL_ESTIMATED_WEIGHT: "Total Weight (KG)",
				LBL_TOTAL_CARTON: "Total Carton",
				LBL_PHOTO: "Photo",
				LBL_REMARKS: "Remark",

				BTN_COPY: "Copy",
				BTN_ADD: "Add",
				BTN_DELETE: "Cancel",
				BTN_UPLOAD_PHOTO: "Upload Product Image",
				BTN_SUPPORT_DOCUMENT: "Upload Support Document",

				EXAMPLE_ENTER_PRODUCT_CATEGORY: "For Example Commodity Desc: Shirt, Shoes, Toy",
				EXAMPLE_ENTER_BRAND: "For Example Enter Multiple Brand: Addidas, Nike",

				PH_GOODS_AMOUNT_FROM: "Enter Amount From",
				PH_GOODS_AMOUNT_TO: "Enter Amount To",
				PH_GOODS_AMOUNT_CURRENCY_SYMBOL: "Enter Currency Symbol",

				MSG_GOODS_AMOUNT_FROM_GREATER_TO: "Amount To must be greater than Amount From",
				MSG_GOODS_AMOUNT_FROM_REQUIRED: "Amount From Required"
			},

			BTN_SUBMIT: "Submit",
			BTN_RESET: "Reset",
			SUCCESS: "Inquiry Quotation has been generate and Inquiry No: {inquiry_no}.",
			FAILED: "Oopps ... ..., Sorry Inquiry Quotation Failed to Generate. Please try again.",
			MSG_FAILED_UPLOAD_IMGAE: "Sorry Product Image Failed to Upload",
			MSG_FAILED_UPLOAD_DOCUMENT: "Sorry Document Failed to Upload",
			MSG_NO_ADDRESS: "Delivery Addresses not found. Please create a new delivery address before requesting for a quote",
			MSG_BILL_ADDRESS_NOT_FOUND: "Billing Address not found. Please set.",
			UPLOAD: {
				PRODUCT_IMAGE: "Upload Product Image",
				SUPPORTED_DOCUMENT: "Upload Supported Document",
				LBL_UPLOAD_PHOTO: "Click to select the images.",
				LBL_UPLOAD_FILE: "Click to select the file.",
				SUPPORT_IMAGE_SIZE: "Supported size should be less than 1 MB!",
				MSG_SUPPORT_IMAGE_SIZE_FORMAT: "Supported size should be less than 1 MB and format .jpg, .jpeg, .png",
				SUPPORT_DOCUMENT_SIZE: "Supported size should be less than 10 MB!",
				MSG_SUPPORT_FILE_SIZE_FORMAT: "Supported size should be less than 10 MB! and format .xls, .xlsx, .pdf, .doc, .docs, .csv"
			},
			MSG_SUCCESFUL_SAVE_AS_DRAFT: "Successful save as draft",
			MSG_BLOCK_EDIT: "You are not allow to edit this inquiry quotation.",

			MSG_CONFIRMATION_SUBMIT: "Please ensure all details are correct before submitting.",

			RESET_FORM_CONFIRMATION: "All Information will be reset. Are you sure?",
			MSG_BILL_ADDRESS_INCOMPLETE: "Billing Address Incomplete",
			MSG_BLOCK_ACCESS_QUOTATION_DETAIL: "This quotation not belong {account_name}. You are not alow to access. ",
			MSG_DOCUMENT_NUMBERING_NOT_SET: "Inquiry No. Format not set. Please contact system admin."
		},
		QUOTATION_DETAIL: {
			LBL_AGREE_TERMS_COND: "By Clicking this you agree ",
			LBL_AGREE_TERMS_COND1: "Terms & Condition",
			TERMS_COND_TITLE: "Terms & Condition",
			ACKNOWLEDGE_QUOTATION_TITLE: "Quotation Detail",
			ADDON_TNC_DESC: "Our company reserves the right to add, amend, annual, modify or vary any of the terms and conditions herein from time to time without any prior notice.",
			QUOTATION_NOT_EXISTS: "Quotation Not Exists",
			NO_ALLOW_REJECT_QUOTATION: "No Allow to Reject Quotation",
			NO_ALLOW_ACKNOWLEDGE_QUOTATION: "No Allow to Acknowledge Quotation",
			QUOTATION_EXPIRE_MESSAGE: "{quotation_no} will expired on {expired_on} ",
			QUOTATION_REVISE: "This {quotation_no} quotation is revised. For further information, please contact customer service.",
			QUOTE_PRICE_HEADER: {
				COL_NO: "No",
				COL_QUOTE_DESC: "Product Desc",
				COL_CATEGORY: "Category",
				COL_TRANSIT: "Transit",
				COL_WEIGHT: "Weight",
				COL_SERVICE_CHARGES: "Service Charges"
			}
		},
		REJECT_QUOTATION: {
			TITLE: "Reject Quotation",
			LBL_QUOTATION_NO: "Quotation No: ",
			LBL_REJECT_REASON: "Reject Reason"
		}
	},
	EPO_MANAGEMENT: {
		EPO_FORMAT: {
			EPO_FORMAT_PRINT_TITLE: "Print EPO",
			PREVIEW_TITLE: "Preview EPO {epo_no}"
		},
		EPO_LISTING: {
			COL_EPO_NO: "EPO No",
			COL_SHIPMENT_TYPE: "Shipment Type",
			COL_SHIPMENT_MODE: "Shipment Mode",
			COL_WAREHOUSE: "Warehouse",
			COL_SUPPLIER: "Supplier",
			COL_COUNTRY: "From/To Country",
			COL_STATUS: "Status",
			COL_EFFECTIVE_ON: "Effective On",
			COL_PRODUCT_NAME: "Commodity Desc",
			COL_REF_TYPE: "Container Type",
			COL_MARKING: "Customer Marking",
			MODULE_NAME: "EPO",

			BTN_VIEW: "View",
			BTN_DELETE: "Delete"
		},
		ORDER: {
			LISTING_TITLE: "EPO Listing",
			CREATE_ORDER_TITLE: "New EPO",
			EPO_ORDER_TITLE: "EPO Order for {epo_no}",
			EPO_ORDER_COPY_TITLE: "Copying EPO Order for {epo_no}",
			EPO_INFO_TITLE: "EPO Info",
			WAREHOUSE_DETAILS_TITLE: "Warehouse Details",
			WAREHOUSE_LOCATION: "Warehouse Location",
			FROM_WAREHOUSE_TITLE: "Warehouse",
			DELIVERY_ADDRESS_TITLE: "Destination",
			SUPPLIER_DETAILS_TITLE: "Supplier Info",
			PRODUCT_DETAILS_TITLE: "Courier / Product Details",
			SPECIAL_REQUEST_TITLE: "Special Request",
			CUSTOMER_NOTE_TITLE: "Customer Notes",
			MSG_TNC_CHECKBOX: "Accepting EPO Terms & Condition",
			MSG_EPO_FORMAT_SETTINGS: "EPO Format Setting not matched. Please contact customer service",
			MSG_NOT_QUOTATION_ACKNOWLEDGE: "<br />All Credit Terms & Service Charges depend final acknowledge quotation.",

			LBL_EPO_NO: "EPO No",
			LBL_EPO_TYPE: "Send To warehouse using courier",
			LBL_PO_EXISTS: "PO Exists?",
			LBL_CURRENCY: "Currency",
			LBL_PLG: "Parcel Lost Guarantee",

			LBL_COMPANY_NAME: "Company Name",
			LBL_PIC: "Person In Charge",
			LBL_EMAIL_ADDRESS: "Email Address",
			LBL_CONTACT_NUMBER: "Mobile Contact No.",
			LBL_BILL_ADDRESS: "Bill Address",
			LBL_OFFICE_CONTACT: "Office Contact No.",
			LBL_OFFICE_FAX_CONTACT: "Office Fax No.",
			LBL_MARKING: "Customer Marking",
			LBL_SUPPLIER: "Supplier",
			LBL_SUPPLIER_ORDER_NO: "Supplier Order No",
			LBL_SUPPLIER_BANK_NAME: "Supplier Bank Name",
			LBL_SUPPLIER_BANK_ACCOUNT_NAME: "Supplier Bank Account Name",
			LBL_SUPPLIER_BANK_ACCOUNT_NO: "Supplier Bank Account No.",
			LBL_SUPPLIER_BANK_BRANCH: "Supplier Bank Branch",
			LBL_EXISTS_PO_NO: "Exists PO No.",
			LBL_DEPOSIT: "Deposit",
			LBL_ESTIMATE_DATE_DELIVERY: "Estimate Delivery Date",
			LBL_WAREHOUSE_TYPE: "Warehouse Type",
			LBL_WAREHOUSE: "Warehouse",
			LBL_PRODUCT_REMARK: "Remarks",
			LBL_EPO_SUBMIT_DATE: "EPO Date",
			LBL_DATE_FROM: "Date From",
			LBL_DATE_TO: "Date To",
			LBL_EPO_DATE: "EPO Date",
			LBL_EPO_STATUS: "EPO Status",

			LBL_SUB_TOTAL: "Sub Total",
			LBL_TOTAL_DISCOUNT: "Total Discount",
			LBL_GRAND_TOTAL_AMOUNT: "Grand Total",

			EPO_TYPE_SELECTION_ORDER: "Order",
			EPO_TYPE_SELECTION_COURIER: "Courier",

			WAREHOUSE_SELECTION_OTHERS: "Others",

			PH_PIC: "Name",
			PH_SPECIAL_REQUEST: "Write a Message...",
			PH_LOADING_PORT: "Enter Loading Port",
			PH_DESTINATION_PORT: "Enter Destination Port",
			PH_ENTER_EPO_DATE_FROM: "EPO Date From",
			PH_ENTER_EPO_DATE_TO: "EPO Date To",

			BTN_NEW_SUPPLIER: "New Supplier",

			COL_EPO_NO: "EPO No",
			COL_CREATED_AT: "Created On",
			COL_COMPANY: "Company",
			COL_SHIPMENT_TYPE: "Shpt. Type",
			COL_SHIPMENT_MODE: "Shpt. Mode",
			COL_DELIVERY_ID: "Delivery Id",
			COL_EPO_DATE: "Epo Date",
			COL_WAREHOUSE: "WHS",
			COL_SUPPLIER: "SPLR",
			COL_COUNTRY: "From/To Country",
			COL_STATUS: "Status",
			COL_EFFECTIVE_ON: "Effective On",
			COL_PRODUCT_NAME: "Commodity Desc",
			COL_REF_TYPE: "Container Type",
			COL_MARKING: "Cust Marking",
			COL_SUPPLIER_NAME: "SPLR Name",
			COL_EPO_TYPE: "Epo Type",
			COL_TOTAL_DEPOSIT: "Ttl Deposit",
			COL_GRAND_TOTAL: "Grand Ttl.",
			COL_REQUEST_PLG: "Parcel Lost Guarantee",

			SHIPPING: {
				LBL_TYPE: "Shipment Type",
				LBL_MODE: "Shipment Mode",
				LBL_MOVEMENT: "Movement Type",
				LBL_TERM: "Shipment Term"
			},

			SUPPLIER: {
				LBL_NAME: "Supplier/Forwarder Name",
				LBL_ADDRESS: "Address",
				LBL_POSTCODE: "Postal Code",
				LBL_CITY: "City",
				LBL_STATE: "State",
				LBL_CONTACT_NUMBER: "Supplier/Forwarder Contact Number",
				LBL_CONTACT_NUMBER_CALLING_CODE: "Supplier/Forwarder Calling Code"
			},

			DELIVERY: {
				LBL_ADDRESS_NAME: "Destination Address",
				LBL_ADDRESS: "Address",
				LBL_BUILDING_NAME: "Building Name",
				LBL_UPSTAIRS: "upstairs",
				LBL_COUNTRY: "Country",
				LBL_STATE: "State",
				LBL_CITY: "City",
				LBL_POSTCODE: "Postal Code",
				LBL_BRN: "Business Register Number"
			},

			PRODUCT: {
				COL_COURIER: "Courier",
				COL_ITEM_CODE: "Item Code",
				COL_PRODUCT_NAME: "Product Name",
				COL_BRAND: "Brand",
				COL_CATEGORY_GROUP: "Type",
				COL_CATEGORY_GROUP_TYPE: "Type Selection",
				COL_CATEGORY_GROUP_OTHERS: "Type Others",
				COL_QUANTITY: "Qty",
				COL_UOM: "Uom",
				COL_UNIT_PRICE: "Unit Price",
				COL_DISCOUNT_AMOUNT: "Discount Amount",
				COL_TOTAL_AMOUNT: "Total Amount",
				COL_REMARKS: "Remark",
				COL_PHOTO: "Photo",
				COL_INV_DESC: "Invoice Desc",

				LBL_CONSIGNMENT_NO: "Consignment No",

				BTN_COPY: "Copy",
				BTN_ADD: "Add",
				BTN_DELETE: "Delete",
				BTN_UPLOAD: "Upload",
				BTN_CHANGE_CATEGORY_GROUP: "Modify Category Group",
				BTN_ADD_CONSIGNMENT_NO: "Add Consignment No",
				BTN_UPLOAD_PHOTO: "Upload Product Image",
				BTN_SUPPORT_DOCUMENT: "Upload Support Document",

				EXAMPLE_ENTER_PRODUCT_CATEGORY: "For Example Commodity Desc: Shirt, Shoes, Toy",
				EXAMPLE_ENTER_BRAND: "For Example Enter Multiple Brand: Addidas, Nike",

				PH_SELECT_CATEGORY_GROUP: "Please Select Category Group",
				PH_GOODS_AMOUNT_FROM: "Enter Amount From",
				PH_GOODS_AMOUNT_TO: "Enter Amount To",
				PH_GOODS_AMOUNT_CURRENCY_SYMBOL: "Enter Currency Symbol",

				MSG_GOODS_AMOUNT_FROM_GREATER_TO: "Amount To must be greater than Amount From",
				MSG_GOODS_AMOUNT_FROM_REQUIRED: "Amount From Required"
			},

			CATEGORY_GROUP_TYPE: {
				MODAL_TITLE: "Category Type Details",

				LBL_CATEGORY_TYPE: "Goods Type",
				LBL_CATEGORY_GROUP_TYPE: "Selection of {category_group_name} product",
				LBL_OTHER_CATEGORY_GROUP_TYPE: "Discribe for {category_group_name} product",
				LBL_VALUABLE_OBJECTS: "Valuable objects",
				LBL_FRAGILE_ITEMS: "Fragile items",
				LBL_OTHER_SENSITIVE_ITEMS: "Other Sensitive Items",
				LBL_FAKE_BRANDS: "Fake Brands",

				PH_OTHER_CATEGORY_GROUP_TYPE: "Please input and discribe for {category_group_name} product",
				PH_FAKE_BRANDS: "Please input and discribe for Fake Brands"
			},
			UPLOAD: {
				PRODUCT_IMAGE: "Upload Product Image",
				SUPPORTED_DOCUMENT: "Upload Supported Document",
				LBL_UPLOAD_PHOTO: "Click to select the images.",
				LBL_UPLOAD_FILE: "Click to select the file.",
				SUPPORT_IMAGE_SIZE: "Supported size should be less than 1 MB!",
				MSG_SUPPORT_IMAGE_SIZE_FORMAT: "Supported size should be less than 1 MB and format .jpg, .jpeg, .png",
				SUPPORT_DOCUMENT_SIZE: "Supported size should be less than 10 MB!",
				MSG_SUPPORT_FILE_SIZE_FORMAT: "Supported size should be less than 10 MB! and format .xls, .xlsx, .pdf, .doc, .docs, .csv"
			},

			NEW_SUPPLIER: {
				MODAL_TITLE: "Create New Supplier"
			},

			BTN_SUBMIT: "Submit",
			BTN_RESET: "Reset",
			SUCCESS: "EPO No: {epo_no} has been generate.",
			SAVE: "EPO No: {epo_no} has been saved.",
			FAILED: "Oopps ... ..., Sorry EPO Order Failed to Generate. Please try again.",
			MSG_FAILED_UPLOAD_IMGAE: "Sorry Product Image Failed to Upload",
			MSG_FAILED_UPLOAD_DOCUMENT: "Sorry Document Failed to Upload",
			MSG_NO_ADDRESS: "Delivery Addresses not found. Please create a new delivery address before requesting for a quote",
			TNC_CHECKBOX: "You must agree Terms & Conditions to continue!",

			MSG_GRAND_TOTAL_AMOUNT_NEGATIVE: "Grand Total amount cannot be negative",
			MSG_PRODUCT_TOTAL_AMOUNT_NEGATIVE: "Product Total amount cannot be negative",
			MSG_PRODUCT_UNIT_PRICE_EMPTY: "Warning! your product unit price contain zero or empty value",
			MSG_SUCCESFUL_SAVE_AS_DRAFT: "Successful save as draft",
			MSG_BLOCK_EDIT: "You are not allow to edit this EPO Order.",
			MSG_DOCUMENT_TYPE: "No Document Type matched for current Marking. Please contact admin",
			MSG_DOCUMENT_NUMBER: "No Document Number matched for current Marking. Please contact admin",
			MSG_EPO_NO_NOT_EXISTS: "Po No. has been used.",

			MSG_CONFIRMATION_SUBMIT: "Please ensure all details are correct before submitting.",
			MSG_PERSONAL_DATA_INCOMPLETE: "Personal Data Incomplete. Please complete first",
			RESET_FORM_CONFIRMATION: "All Information will be reset. Are you sure?",
			AGREE_TNC: "EPO Terms & Condition",
			MSG_DEPOSIT_AMT_GREATER_THAN_EPO: "Grand Total should be not less than TTed Amount",
			MSG_BLOCK_PURCHASE_PARCEL_LOST_GUARANTEE: "Found item(s) mix, you are not allow to purchase parcel lost guarantee. <br/> Please split to 2 order.",
		}
	},
	DEPOSIT_MANAGEMENT: {
		LISTING_TITLE: "Deposit Listing",
		NEW_DEPOSIT_TITLE: "New Deposit",
		EDIT_DEPOSIT_TITLE: "Edit Deposit Information",
		DEPOSIT_DETAILS_TITLE: "Deposit Detail",
		DEPOSIT_INFO_TITLE: "Deposit Info",
		BENEFICIARY_INFO_TITLE: "Beneficiary Info",
		SO_DEPOSIT_TITLE: "SO Deposit Info",

		COL_REF_NO: "Ref No",
		COL_MARKING: "Marking",
		COL_CURRENCY: "Currency",
		COL_DEPOSIT_DATE: "Date",
		COL_SO_AMT: "SO Amt",
		COL_TOTAL_TT: "Total TT",
		COL_TOTAL_LOCAL_TT: "Total Local TT",
		COL_BENE_NAME: "Bene Name",
		COL_REMARKS: "Remarks",
		COL_TYPE: "Type",
		COL_SO_NO: "SO No",
		COL_DELIVERY: "Delivery",
		COL_SO_TRANSFERRED_AMT: "SO Transferred Amt",
		COL_TT: "TT",
		COL_TT_LOC_AMT: "TT Loc Amt",
		COL_ADJUSTMENT: "Adjustment",
		COL_INDICATOR: "+/-",

		BTN_VIEW: "View",
		BTN_DELETE: "Delete",

		LBL_TYPE: "Type",
		LBL_REF_NO: "Ref No",
		LBL_WAREHOUSE: "Warehouse",
		LBL_WAREHOUSE_COUNTRY: "Warehouse Country",
		LBL_WAREHOUSE_DETAILS: "Warehouse Datails",
		LBL_DEPOSIT_DATE: "Deposit Date",
		LBL_MARKING: "Marking",
		LBL_CURRENCY: "Currency",
		LBL_RATE: "Rate",
		LBL_TT_CATEGORY: "TT Category",

		LBL_BENE_TYPE: "Bene Type",
		LBL_REMARK: "Remark",
		LBL_OTHER_AMT: "Other Amount",
		LBL_OTHER_LOCAL_AMT: "Other Local Amount",
		LBL_WITHOUT_OR: "Withouf OR",
		LBL_FINANCE: "Finance %",

		LBL_ACCOUNT_HOLDER: "Account Holder",
		LBL_ACCOUNT_NO: "Account No",
		LBL_RI_NO: "RI No %",
		LBL_CONTACT_NO: "Contact No",
		LBL_CONTACT_PERSON: "Contact Person",
		LBL_BANK_NAME: "Bank Name",
		LBL_BANK_COUNTRY: "Bank Country",
		LBL_BANK_CITY: "Bank City",
		LBL_BANK_ADDRESS: "Bank Address",
		LBL_BENEFICIARY_ADDRESS: "Beneficiary Address",
		LBL_SWIFT_CODE: "Swift Code",
		LBL_INTERMEDIATE_BANK_REMARK: " Intermediate Bank Remark",
		LBL_TT_CHARGES: "TT Charges",
		LBL_LOCAL_TT_CHARGES: "Local TT Charges",
		LBL_EXT_TO_LOCAL: "Ext to Local",

		LBL_TOTAL_TT_AMT: "Total TT Amt",
		LBL_TOTAL_LOC_TT_AMT: "Total Loc TT Amt",
		LBL_OUTGOING_CURRENCY: "Outgoing Currency",
		LBL_CONVERTED_RATE: "Converted Rate",
		LBL_OUTGOINF_TT_AMT: "Outgoing TT Amt",
		LBL_OUTGOING_LOCAL_TT_AMT: "Outgoing Local TT Amt"
	},
	CUSTOMER_MANAGEMENT: {
		CUSTOMER_SUPPLIER: {
			LBL_CUSTOEMR_SUPPLIER_CUSTOMER: "Customer",
			LBL_CUSTOEMR_SUPPLIER_NAME: "Customer Supplier Name",
			LBL_CUSTOMER_SUPPLIER_MOBILE_CONTACT_PIC_NAME: "Mobile Contact PIC Name",
			LBL_CUSTOMER_SUPPLIER_MOBILE_CONTACT_NO: "Mobile Contact No",
			LBL_CUSTOMER_SUPPLIER_MOBILE_ALT_CONTACT_PIC_NAME: "Mobile Alt Contact PIC Name",
			LBL_CUSTOMER_SUPPLIER_MOBILE_ALT_CONTACT_NO: "Mobile Alt Contact No",
			LBL_CUSTOMER_SUPPLIER_OFFICE_TEL_PIC_NAME: "Office Tel PIC Name",
			LBL_CUSTOMER_SUPPLIER_OFFICE_TEL_NO: "Office Tel No",
			LBL_CUSTOMER_SUPPLIER_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_SUPPLIER_ADDRESS: "Address",
			LBL_CUSTOMER_SUPPLIER_POSTCODE: "Postcode",
			LBL_CUSTOMER_SUPPLIER_OTHER_POSTCODE: "Other Postcode",
			LBL_CUSTOMER_SUPPLIER_CITY: "City",
			LBL_CUSTOMER_SUPPLIER_OTHER_CITY: "Other City",
			LBL_CUSTOMER_SUPPLIER_STATE: "State",
			LBL_CUSTOMER_SUPPLIER_COUNTRY: "Country",
			LBL_CUSTOMER_SUPPLIER_EMAIL: "Email",
			LBL_CUSTOMER_SUPPLIER_REMARK: "Remark"
		}
	},
	CUSTOMER: {
		SIDE_BAR: {
			LOGIN_EMAIL: "Login Email",
			NICKNAME: "Nickname",
			PHONE: "Phone",
			COMPANY_NAME: "Account Name",
			ACCOUNT_MANAGEMENT: "Account Management",
			ROLE_MANAGEMENT: "Role Management",
			DASHBOARD: "Dashboard",
			PROFILE_AND_ACCOUNT: "Profile & Account",
			DELIVERY_ADDRESS: "Delivery Address",
			SUPPLIER: "Supplier Management",
			ADD_NEW_ADDRESS_LISTING_TITLE: "Add new address to listing",
			LEDGER_TRANSACTION: "Transaction",
			NOTIFICATION: "Notification",
			CHANGE_PASSWORD: "Change Password",
			SUB_ACCOUNT: "User Management",
			ORDER: "My EPO",
			WAREHOUSE_ADDRESS: "Agent Warehouse",
			MARKING: "Marking",
			DATA_FILTER: "Data Filter",
			DEPOSIT: "Deposit"
		},
		PERSONAL_INFO: {
			PERSONAL_INFO_TITLE: "Account Information",
			UPDATE_PERSONAL_INFO_TITLE: "Update your account information",
			CUSTOMER_INFO_TITLE: "Login Account",
			CONTACT_INFO_TITLE: "Contact Info",
			ADDRESS: "Billing Address",
			CHANGE_PASSWORD: "Change Password",
			CHANGE_NICKNAME: "Change Nickname",
			CANCEL: "Cancel",
			EDIT: "Edit",
			SAVE: "Save",
			NEW_NICKNAME: "New Nickname",
			MSG_BLOCK_EDIT_NICKNAME: "You are not allow to edit/save Nickname",
			SUCCESFUL_UPDATE_MSG: "Nickname update successful",

			LBL_CONTACT_PIC: "Contact PIC",
			LBL_NRIC: "NRIC / BRN",
			LBL_AVATAR: "Avatar",
			LBL_FIRST_NAME: "First Name",
			LBL_LAST_NAME: "Last Name",
			LBL_FULL_NAME: "Full Name",
			LBL_NAME: "Name",
			LBL_COMPANY_NAME: "Company Name",
			LBL_CONTACT_PHONE: "Contact Phone",
			LBL_COMPANY_SITE: "Company Site",
			LBL_EMAIL_ADDRESS: "Email Address",
			LBL_BUILDING_NAME: "Building Name",
			LBL_ADDRESS: "Address",
			LBL_COUNTRY: "Country",
			LBL_STATE: "State",
			LBL_CITY: "City",
			LBL_POSTCODE: "Postcode",

			PH_CONTACT_PHONE: "Phone",
			PH_EMAIL_ADDRESS: "Email",
			PH_COMPANY_SITE: "Website URL",
			PH_CONTACT_PIC: "Contact PIC",
			PH_BUILDING_NAME: "Building Name",
			PH_ADDRESS: "Address",
			PH_COUNTRY: "Country",
			PH_STATE: "State",
			PH_CITY: "City",
			PH_POSTCODE: "Postcode",

			AVATAR_MESSAGE: "Allowed file types: png, jpg, jpeg.",
			COMPANY_NAME_MESSAGE: "If you want your invoices addressed to a company. Leave blank to use your full name.",
			CONTACT_PHONE_MESSAGE: "We'll never share your contact number with anyone else.",
			MSG_UPLOAD_AVATAR: "Successfull upload avatar",
			MSG_FAILED_UPLOAD_AVATAR: "Failed upload avatar"
		},
		DASHBOARD: {
			DASHBOARD_TITLE: "Dashboard",
			QUOTATION_SUMMARY_TITLE: "Quotation Summary",
			EPO_SUMMARY_TITLE: "EPO Summary",
			ACCOUNT_SUMMARY_TITLE: "Account Summary",
			QUOTATION_STATS_TITLE: "Quotation Stats",
			EPO_STATS_TITLE: "EPO Stats",
			ACCOUNT_STATS_TITLE: "Account Stats",

			APPROVED_LABEL: "Approved",
			REJECTED_LABEL: "Rejected",

			QUOTATION_STATUS_DRAFT: "Draft",
			QUOTATION_STATUS_PENDING_VERIFY: "Pending Verify"
		},
		DELIVERY_ADDRESS: {
			CREATE_NEW_ADDRESS_TITLE: "New Delivery Address",
			EDIT_ADDRESS_BOOK: "Edit Delivery Address",
			ADDRESS_BOOK_DETAIL: "Delivery Address Detail",
			EDIT_EXISTING_ADDRESS_BOOK: "Update Existing Address",
			PIC_TITLE: "Person in Charge Information",
			DELIVERY_ADDRESS_TITLE: "Delivery Address",
			BILLING_ADDRESS_TITLE: "Billing Address",
			CONTACT_INFORMAION_TITLE: "Contact Information",
			STATES_VERIFY_TITLE:
				'The following states\' addresses need to be verified by admin.<br />If an inquiry quote exists, The following states\' addresses is not allowed to be edited: <span class="text-capitalize">{states}</span>',

			LBL_ADDRESS_NAME: "Name / Company Name",
			LBL_DELIVERY_ID: "Delivery ID",
			LBL_PIC_NAME: "Person In Charge Name",
			LBL_CONTACT_NO: "Contact No.",
			LBL_MOBILE_CONTACT_NO: "Mobile Contact No.",
			LBL_OFFICE_CONTACT_NO: "Office Contact No.",
			LBL_MOBILE_CONTACT_NO_SECOND: "Mobile Contact No (Alt).",
			LBL_FAX_CONTACT_NO: "Fax No.",
			LBL_BUILDING_NAME: "Building Name",
			LBL_ADDRESS: "Address",
			LBL_COUNTRY: "Country",
			LBL_STATE: "State",
			LBL_CITY: "City",
			LBL_POSTCODE: "Postal/Zip Code",
			LBL_UPSTAIRS: "Upstairs",
			UPSTAIRS_YES: "Yes",
			LBL_EMAIL: "Email",
			LBL_BILLING_ADDRESS: "Bill Address",
			BILLING_ADDRESS_YES: "Yes",
			LBL_DELIVERY_ADDRESS_RECEIVING_HOUR: "Receiving Hour",
			LBL_DELIVERY_ADDRESS_RECEIVING_DAY: "Receiving Day",
			RECEIVING_HOUR_REMARK: "Deliver Goods between 09:00 a.m. To 06:00 p.m. For any receiving hour's concern, you may contact customer services.",

			EMPTY_MSG: "Please enter ",
			SUCCESFUL_SAVE_MSG: "Delivery Address successfully created",
			SUCCESFUL_UPDATE_MSG: "Delivery Address update successful",
			MSG_BILL_ADDRESS_CONFIRMATION: "You have set the billing address. Are you sure to change it?",

			LBL_MOBILE_CONTACT_NO_PIC: "Mobile Contact PIC",
			LBL_MOBILE_CONTACT_NO_PIC2: "Mobile Contact(ALT) PIC",
			LBL_MOBILE_CONTACT_NO_CALLING_CODE: "Mobile Contact Calling Code",
			LBL_MOBILE_CONTACT_NO_CALLING_CODE1: "Mobile Contact (2) Calling Code",
			LBL_OFFICE_CONTACT_NO_CALLING_CODE: "Office Contact Calling Code",
			LBL_OFFICE_CONTACT_NO_PIC: "Office Contact PIC",
			LBL_OFFICE_CONTACT_PIC_NAME: "Office PIC",
			LBL_BRN: "National Registration Identity Card / Business Register Number",
			MSG_BLOCK_EDIT_DELIVERY_ADDRESS: "You are not allow to edit/save delivery address",
			LBL_AGREE_TERMS_COND: 'By Clicking this you agree <a href="{url}" target="_blank">Terms & Condition</a>',

			PH_RECEIVING_HOUR_FROM: "-- Receiving Hour From --",
			PH_RECEIVING_HOUR_TO: "-- Receiving Hour To --",

			PH_RECEIVING_DAY_FROM: "-- Receiving Day From --",
			PH_RECEIVING_DAY_TO: "-- Receiving Day To --",

			DELIVERY_ID_VALIDATION: {
				DELIVERY_ID_LENGTH: "Must be {min} to {max} character.",
				DELIVERY_ID_SPECIAL_CHARACTER: "Allow {len} special character({special_character}) character only/Any alphanumeric character.",
				DELIVERY_ID_UNIQUE: "Delivery ID is available"
			},

			MSG_MARKING_ID_SAVE_AS_DELIVERY_ID: "Delivery Id will be assign after 1st marking id generated.",
			MSG_CANNOT_EDIT__DELIVERY_ID_MARKING: "Delivery Id assigned with 1st marking id generated. You not allow to edit",
			MSG_BLOCK_UPDATE_DELIVERY_ID: "Delivery Id used in Quotation/EPO, you are not allow to edit",
			MSG_BLOCK_UPDATE_COUNRTY_STATE_ID: "Delivery Address used in Quotation/EPO, you are not allow to edit country/state."
		},
		ADDRESS_LISTING: {
			ADDRESS: "Address",
			ADDRESS_LISTING_TITLE: "Delivery Address Listing",
			DELIVERY_ID: "Delivery ID",
			ADD_NEW_ADDRESS_LISTING_TITLE: "Add new address to listing",
			EDIT_ADDRESS_BOOK: "Edit Address",
			REMARK_ADDRESS_NAME_TITLE: "Company Name",
			REMARK_DEFAULT_BILLING_ADDRESS: "BILLING ADDRESS",
			RECEIVING_HOUR: "Receiving Hour",
			ACTIVE: "Active",
			INACTIVE: "Inactive"
		},
		SUPPLIER: {
			MODULE_NAME: "Supplier",
			EDIT_SUPPLIER: "Edit Supplier",
			SUPPLIER_DETAIL: "Supplier Detail",
			EDIT_EXISTING_SUPPLIER: "Update Existing Supplier",
			EXISTING_SUPPLIER_DETAIL: "View Existing Supplier",
			NEW_SUPPLIER: "New Supplier",

			TITLE_ADDRESS_INFO: "Supplier Address Information",
			TITLE_CONTACT_INFO: "Supplier Contact Information",

			LBL_NAME: "Supplier Name",
			LBL_PIC_NAME: "Person In Charge Name",
			LBL_CONTACT_NO: "Contact No.",
			LBL_MOBILE_CONTACT_NO: "Mobile Contact No.",
			LBL_OFFICE_CONTACT_NO: "Office Contact No.",
			LBL_MOBILE_CONTACT_NO_SECOND: "Mobile Contact No (Alt).",
			LBL_BUILDING_NAME: "Building Name",
			LBL_ADDRESS: "Address",
			LBL_COUNTRY: "Country",
			LBL_STATE: "State",
			LBL_CITY: "City",
			LBL_POSTCODE: "Postal/Zip Code",
			LBL_EMAIL: "Email",
			LBL_REMARK: "Remark",

			EMPTY_MSG: "Please enter ",
			SUCCESFUL_SAVE_MSG: "Customer Supplier successfully created",
			SUCCESFUL_UPDATE_MSG: "Customer Supplier update successful",

			LBL_MOBILE_CONTACT_NO_PIC: "Mobile Contact Person In Charge",
			LBL_MOBILE_CONTACT_NO_PIC2: "Mobile Contact(2) Person In Charge",
			LBL_MOBILE_CONTACT_NO_CALLING_CODE: "Mobile Contact Calling Code",
			LBL_MOBILE_CONTACT_NO_CALLING_CODE1: "Mobile Contact (2) Calling Code",
			LBL_OFFICE_CONTACT_NO_CALLING_CODE: "Office Contact Calling Code",
			LBL_OFFICE_CONTACT_NO_PIC: "Office Person In Charge",
			LBL_OFFICE_CONTACT_PIC_NAME: "Office Person In Charge",

			COL_MOBILE_CONTACT_NO_PIC: "Person In Charge",
			COL_SUPPLIER_NAME: "Name",
			COL_SUPPLIER_ADDRESS: "Address"
		},
		ACCOUNT_MANAGEMENT: {
			SETUP_ACCOUNT: "Set Up Account",
			ACCOUNT_DETAIL_TITLE: "Basic Information",
			CUSTOMER_PROFILE_TITLE: "Profile & Account",
			PROCESS_STATE: "Processes State",
			LBL_EXTRA_EMAIL_CAPTION: "For Example: test_1@mail.com;test_2@mail.com",
			ACCOUNT_MANAGEMENT_TITLE: "Account Listing",
			ADD_NEW_ACCOUNT_MANAGEMENT_TITLE: "Add new company to listing",
			EDIT_ACCOUNT_MANAGEMENT: "Edit Account Information",
			ACCOUNT_MANAGEMENT_DETAILS: "Account Information Detail",
			ACCOUNT_UPDATE_LOG: "Request Change Account Name / BRN",
			ADMIN_VERIFLYING: "Admin is veriflying your account please come back later.",
			WAITTING_FOR_ADMIN_APPROVE: "Waiting For Admin Approve",
			ACCOUNT_NAME: "Account Name / Company Name",
			NRIC: "NRIC / Passport",
			COMPANY_NAME: "Company Name",
			PENDING_ACCOUNT: "Kindly remind you still have account in pending",
			BUSINESS_REGISTER_NUMBER_NRIC: "New Register No./NRIC/Passport",
			BUSINESS_REGISTER_NUMBER: "New Register No.",
			OLD_BUSINESS_REGISTER_NUMBER: "Old Register No.",
			CONTACT_NUMBER: "Contact Number",
			BILLING_ADDRESS_TITLE: "Billing Address",
			UPLOAD_PHOTO: "Upload NRIC/BRN",
			DELIVERY_ADDRESS_TITLE: "Billing Address",
			CONTACT_DETAILS_TITLE: "Contact Details",
			CREATE_NEW_ACCOUNT: "Create New Account",
			EDIT_ACCOUNT: "Edit Account",
			LBL_ACCOUNT_NAME: "Account Name",
			CONTACT_INFORMAION_TITLE: "Contact Information",
			TNC_CHECKBOX: "You must agree to continue!",
			LBL_EMAIL: "Email",
			LBL_EXTRA_EMAIL: "Add Another Email",
			LBL_MOBILE_CONTACT_NO: "Mobile Contact",
			LBL_MOBILE_CONTACT_NO_PIC: "Mobile Contact Personal In Charge",
			LBL_MOBILE_CONTACT_NO_SECOND: "Mobile Contact No (Alt).",
			LBL_MOBILE_CONTACT_NO_PIC2: "Mobile Contact(Alt) Person In Charge",
			LBL_OFFICE_CONTACT_NO: "Office Contact No.",
			LBL_OFFICE_CONTACT_NO_CALLING_CODE: "Office Contact No.",
			LBL_OFFICE_CONTACT_NO_PIC: "Office Person In Charge",
			LBL_PIC_NAME: "Personal In Charge",
			LBL_BUILDING_NAME: "Building Name",
			LBL_UPSTAIRS: "Upstairs",
			LBL_BILLING_EMAIL: "Billing Email",
			UPSTAIRS_YES: "Yes",
			LBL_CONTACT_NO: "Contact No.",
			LBL_CONTACT_NO_PIC: "Contact PIC",
			LBL_BUSINESS_REGISTER_NUMBER: "Business Register No.",
			LBL_NEW_BRN: "New Business Register No.",
			SUCCESFUL_SAVE_MSG: "Account have successful created.",
			SUCCESFUL_UPDATE_MSG: "Submission success.",
			COL_REQUEST_TYPE: "Request Type",
			COL_ACCOUNT_NAME: "Account/Company Name",
			COL_NRIC_OR_BRN: "NRIC/Passport/Business Register No.",
			COL_PROCESS_STATE: "Processes State",
			RADIO_INDIVIDUAL: "Individual",
			RADIO_COMPANY: "Company",
			LBL_NAME: "Name",
			LBL_NICKNAME: "Nickname",
			LBL_TANDC: "Terms and Conditions",
			LBL_LOGIN_EMAIL: "Login Email",
			LBL_COMPANY_NAME: "Company Name",
			LBL_NRIC: "NRIC/Passport",
			LBL_BRN: "Business Register No.",
			LBL_NRIC_BRN: "NRIC/Passport/Business Register No.",
			LBL_PHOTO: "Upload your NRIC/Passport Photo",
			LBL_COMPANY_PHOTO: "Upload your SSM Photo",
			SUCCESFUL_SETUP_ACCOUNT: "Account was successfully created",
			ACCOUNT_INTRO_TITLE: "Manage and protect your account.",
			ACCOUNT_CONTACT_TITLE: "My Contact",
			ACCOUNT_STATUS: "Processes State",
			LBL_EXTRA_EMAIL_ALT: "Extra Email (ALT)",
			CUSTOMER_DETAILS_TITLE: "Account Details",
			LBL_TNC_AGREE: 'By Clicking this you agree <a href="{url}" target="_blank">Terms & Condition</a>.',
			MSG_BLOCK_CREATE_NEW_ACCOUNT: "Account's marking record not found, Please request inqury or contact customer service.",
			BTN_CREATE_ACCOUNT: "New Account",
			MSG_CONFIRMATION_CREATE_INQUIRY_QUOTE: "Request a quotation for new account?",
			COL_NEW_ACCOUNT_NAME: "New Account/Company Name",
			MSG_ACCOUNT_REGISTERED: "Account registered with another email. <br />If any service inquiry. Please contact customer service"
		},
		ROLE_MANAGEMENT: {
			ROLE_LISTING_TITLE: "Role Listing",
			CREATE_NEW_ROLE_TITLE: "Create New Role",
			EDIT_ROLE_MANAGEMENT: "Edit Role Information",
			ROLE_MANAGEMENT_DETAILS: "Role Information Detail",
			CREATE_NEW_ROLE: "Create New Role",
			EDIT_ROLE: "Edit Role",
			ROLE_NAME: "Role Name",
			ROLE_DESCRIPTION: "Role Description",
			DEFAULT_SORT: "Default Sort",
			SORT_ALPHABET: "Sort by Alphabet",
			ROLE_STATUS: "Status",
			MODIFY_BY: "Modify BY",
			STATUS: "Status",
			SUCCESSFUL_SAVE_MSG: "Role was successfully created",
			SUCCESSFUL_UPDATE_MSG: "Submission success.",
			ERROR_NAME_EXISTS_MSG: "Name exists. Please choose a new name.",
			ROLE_PERMISSION: "Permission"
		},
		SUB_ACCOUNT_MANAGEMENT: {
			SUB_ACCOUNT_MANAGEMENT_TITLE: "Sub-Account Listing",
			CREATE_NEW_SUB_ACCOUNT: "New Sub-Account",
			EDIT_SUB_ACCOUNT: "Edit Sub-Account",
			VIEW_SUB_ACCOUNT: "View Sub-Account",
			COL_USERNAME: "Username",
			COL_NICKNAME: "Nickname",
			COL_ROLE: "Role",
			COL_STATUS: "Status",
			COL_CREATED_BY: "Created By",
			COL_CREATED_AT: "Created At",
			COL_LAST_UPDATED_BY: "Last Modified By",
			COL_UPDATED_AT: "Last Modified At",
			COL_LAST_LOGIN: "Last Login",

			LBL_USERNAME: "Username",
			LBL_USERNAME_DESCRIPTION: "Full Username: ",
			LBL_USERNAME_DESCRIPTION_2: "Username cannot be changed",
			LBL_NICKNAME: "Nickname",
			LBL_NICKNAME_DESCRIPTION: "Nickname can be changed later",
			LBL_USERNAME_PREFIX_TOOLTIP_TITLE: "Username Prefix",
			LBL_USERNAME_PREFIX_TOOLTIP: "This username prefix is automatically generated. User will have to login using the full username, for example:",
			LBL_ONE_TIME_PASSWORD: "One-Time Password",
			LBL_ONE_TIME_PASSWORD_DESCRIPTION: "User is required to reset their password when they login for the first time",
			LBL_ROLE: "Role",
			LBL_EMAIL: "Email",
			LBL_TO_SEND_EMAIL_DESCRIPTION: "An email with login instructions, including username & one-time password will be sent to the email address provided",
			LBL_DATA_FILTER: "Data Filter",
			LBL_IGNORE_FILTER: "Ignore Data Filter",
			LBL_CREATED_BY: "Created By",
			LBL_CREATED_AT: "Created At",
			LBL_LAST_UPDATED_BY: "Modified By",
			LBL_LAST_UPDATED_AT: "Modified At",
			LBL_LAST_LOGIN: "Last Login",
			LBL_STATUS: "Status",

			CHECKBOX_TO_SEND_EMAIL: "Send Login Instructions",

			BTN_CREATE_ROLE: "Create New Role",
			BTN_CREATE_DATA_FILTER: "Create New Data Filter",
			CREATED_MSG: "Sub-account has been created.",
			UPDATED_MSG: "Sub-account details have been updated."
		},
		DATA_FILTER: {
			LISTING_TITLE: "Data Filter Listing",
			CREATE_NEW_TITLE: "Create New Data Filter",
			EDIT_INFO: "Edit Data Filter Information",
			DETAILS: "Data Filter Information Detail",
			CREATE_NEW: "Create New Data Filter",
			EDIT: "Edit Data Filter",
			NAME: "Data Filter Name",
			DESCRIPTION: "Data Filter Description",
			SUB_ACCOUNT: "Sub Account(s)",
			MARKING: "Marking(s)",
			ASSIGN_TO_USER: "Assign to User(s)",
			STATUS: "Status",
			MODIFY_BY: "Modify BY",
			SUCCESSFUL_SAVE_MSG: "Data Filter was successfully created",
			SUCCESSFUL_UPDATE_MSG: "Submission success.",
			ERROR_NAME_EXISTS_MSG: "Name exists. Please choose a new name."
		},
		CHANGE_PASSWORD: {
			CHANGE_PASSWORD: "Change Password",
			SUCCESFUL_UPDATE_MSG: "Change Password Successfully",
			CURRENT_PASSWORD: "Current Password",
			FORGET_PASSWORD: "Forgot Password?",
			NEW_PASSWORD: "New Password",
			VERIFY_PASSWORD: "Verify Password",
			PASSWORD_EXPIRE:
				"Configure user passwords to expire periodically. Users will need warning that their passwords are going to expire, or they might inadvertently get locked out of the system!",
			INVALID_CURRENT_PASSWORD: "Invalid Current Password",
			CONFIRMATION_PASSWORD_ERROR: "New password and confirm password do not match.",
			CHANGE_SUB_ACCOUNT_PASSWORD: "Change Sub-Account Password"
		},
		MARKING: {
			CUSTOMER_MARKING: "Customer Marking",
			CUSTOMER_MARKING_ID: "Customer Marking ID",
			MARKING: "Marking",
			CREDIT_TERMS: "Credit Terms",
			COMPANY_ID: "Company",
			DAYS: "Days",
			CREDIT_LIMIT: "Credit Limit",
			STATUS: "Status"
		},
		BUTTON: {
			SAVE: "Save",
			CANCEL: "Cancel",
			EDIT: "Edit",
			BACK: "Back",
			VIEW: "View"
		}
	},
	HEADER: {
		HOME: "Home",
		OUR_SERVICE: "Our Service",
		QUICK_USER: {
			QUICK_USER_TITLE: "Profile & Account",
			BTN_SIGNOUT: "Sign out",
			MY_PROFILE: "My Profile",
			MY_PROFILE_DESCRIPTION: "Account settings and more",
			NO_AUTHENTICATION_TITLE: "Login",
			NO_AUTHENTICATION_TITLE_SUB_ACCOUNT: "Login Sub-Account"
		}
	},
	FOOTER: {
		GIMSYSTEM: "Gim System",
		NAVIGATION: {
			ABOUT: "About",
			TEAM: "Team",
			CONTACT: "Contact"
		}
	},
	WAREHOUSE: {
		TITLE: "Agent Warehouse",
		SUB_TITLE: "When you make a purchase in the shopping site, please fill out the following shipping address:",
		LBL_CONTACT: "Contact",
		LBL_REMARK: "Remark",
		LBL_MOBILE: "Mobile"
	}
};
