import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import CoreSettingsJs from "@shared/src/core/services/settings.js";
import { SET_PERMISSION } from "@/core/services/store/config.module";
import router from "../../../router";
import i18n from "@/core/plugins/vue-i18n";

// action types
export const EMPTY_ERROR = "emptyError";
export const VERIFY_AUTH = "verifyAuth";
export const SWITCH_ACCOUNT = "switchAccount";
export const LOGIN = "login";
export const LOGIN_SUB_ACCOUNT = "loginSubAccount";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const EMAIL_VERIFICATION = "emailVerification";
export const FORGOT_PASSWORD = "forgotPassword";
export const FORGOT_PASSWORD_VERIFY_TOKEN = "forgotPasswordVerifyToken";
export const FORGOT_PASSWORD_UPDATE_PASSWORD = "forgotPasswordUpdatePassword";
export const UPDATE_PASSWORD = "updateUser";
export const UPDATE_CURRENT_USER_INFO = "updateUserInfo";
export const SET_AUTH_TOKEN = "setAuthToken";
export const SEND_VERIFICATION_EMAIL = "sendEmailVerification";
export const RESET_SUB_ACCOUNT_ONE_TIME_PASSWORD = "changeSubAccountOneTimePassword";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_USER = "setUser";
export const SET_AUTH = "setAuth";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const CLEAR_ERROR = "clearError";

const state = {
	errors: null,
	user: {
		id: null,
		email: null,
		nickname: null,
		name: null,
		calling_code: null,
		contact_number: null,
		register_no: null,
		company_name: null,
		company_website: null,
		avatar: null,
		contact_pic: null,
		marking_list: [],
		all_account_list: [],
		login_id: null,
		token: JwtService.getToken(),
		is_sub_account: null,
		sub_account_exist: false,
		status: null,
		access_sub_account: false
	},
	isAuthenticated: !!JwtService.getToken()
};

const getters = {
	currentUser(state) {
		return state.user;
	},
	isAuthenticated(state) {
		return state.isAuthenticated;
	},
	isAccountExists(state) {
		return CoreSettingsJs.emptyObjectValidation(state.user.all_account_list) ? false : true;
	},
	isCurrentAccountMarkingExists(state) {
		return CoreSettingsJs.emptyObjectValidation(state.user.marking_list) ? false : true;
	},
	AccessSubAccount(state) {
		return state.user.access_sub_account;
	}
};

const actions = {
	[EMPTY_ERROR](context) {
		context.commit(CLEAR_ERROR);
	},
	[LOGIN](context, credentials) {
		return new Promise((resolve) => {
			ApiService.post("auth", credentials, true)
				.then((response) => {
					let data = response.data;
					if (data.code === 200) {
						if(data.result.pw_expire_date == 0){
							context.commit(SET_AUTH, data.result);
							
							context.commit(SET_PERMISSION, data.result.permission_list);
							
						} else {
							context.commit(SET_USER, data.result);
						}
						resolve(data);
				
					} else if (data.code === 2001) {
						if (data.result.error.toLowerCase() == "bad credentials") {
							context.commit(SET_ERROR, [i18n.t("AUTH.LOGIN.MSG_BAD_CREDENTIALS")]);
						} else if (data.result.error.toLowerCase() == "your account is not verified yet") {
							context.commit(SET_ERROR, [i18n.t("AUTH.LOGIN.MSG_ACCOUNT_NOT_VERIFIED")]);
							let email = credentials.username;
							Swal.mixin({
								customClass: {
									confirmButton: "btn btn-primary m-0 mr-2",
									cancelButton: "btn btn-outline-primary m-0"
								},
								buttonsStyling: false
							})
								.fire({
									html: i18n.t("AUTH.LOGIN.MSG_ACCOUNT_NOT_VERIFIED_DIALOG"),
									icon: "warning",
									showCancelButton: true,
									cancelButtonText: i18n.t("AUTH.EMAIL_VERIFICATION.RESEND_CANCEL_BUTTON"),
									confirmButtonText: i18n.t("AUTH.EMAIL_VERIFICATION.RESEND_EMAIL_BUTTON"),
									allowOutsideClick: false,
									allowEscapeKey: false
								})
								.then((response) => {
									if (response.isConfirmed == true) {
										this.dispatch(SEND_VERIFICATION_EMAIL, { email: email }).then((response) => {
											if (response.code == "200") {
												Swal.fire({
													icon: "success",
													title: i18n.t("AUTH.EMAIL_VERIFICATION.SUCCESSFUL_SENT_EMAIL_TITLE"),
													html: i18n.t("AUTH.EMAIL_VERIFICATION.SUCCESSFUL_SENT_EMAIL_MESSAGE", { hour: response.result.hour })
												});
											}
										});
									}
								});
						}
						/**Expire Validity */
						else {
							context.commit(SET_ERROR, [data.msg]);
						}
					} else {
						context.commit(SET_ERROR, [data.msg]);
					}
				});
			// .catch((error) => {
			// 	if(error.response.status==403){
			// 		Swal.fire({
			// 			title: i18n.t("COMMON.MSG.TIMEOUT_ERROR"),
			// 			text: i18n.t("COMMON.MSG.TIMEOUT_MESS"),
			// 			icon: "warning",
			// 			customClass: {
			// 				confirmButton: "btn btn-primary m-0 mr-2",
			// 				cancelButton: "btn btn-outline-primary m-0"
			// 			},
			// 			confirmButtonClass: "btn btn-secondary",
			// 			heightAuto: false
			// 			}).then(()=>{
			// 				router.push({ name: "login" });
			// 			});
			// 	}else{
			// 		context.commit(SET_ERROR, [error]);
			// 	}

			// });
		});
	},
	[LOGIN_SUB_ACCOUNT](context, credentials) {
		return new Promise((resolve) => {
			ApiService.post("auth-sub-account", credentials, true)
				.then((response) => {
					let data = response.data;
					if (data.code === 200) {
						context.commit(SET_AUTH, data.result);
						context.commit(SET_USER, data.result);
						context.commit(SET_PERMISSION, data.result.permission_list);
						resolve(data);
					} else if (data.code === 2001) {
						if (data.result.error.toLowerCase() == "bad credentials") {
							context.commit(SET_ERROR, [i18n.t("AUTH.LOGIN.MSG_BAD_CREDENTIALS")]);
						} else {
							context.commit(SET_ERROR, [data.msg]);
						}
					} else {
						context.commit(SET_ERROR, [data.msg]);
					}
				})
				.catch((error) => {
					context.commit(SET_ERROR, [error]);
				});
		});
	},
	[LOGOUT](context) {
		context.commit(PURGE_AUTH);
	},
	[VERIFY_AUTH](context) {
		if (JwtService.getToken()) {
			return new Promise((resolve) => {
				ApiService.post("verify")
					.then((response) => {
						let data = response.data;
						if (data.code !== 200) {
							context.commit(PURGE_AUTH);
						} else {
							context.commit(SET_USER, data.result);
							context.commit(SET_PERMISSION, data.result.permission_list);
						}
						resolve(data);
					})
					.catch(() => {
						context.commit(PURGE_AUTH);
					})
					.finally(() => {
						if (!state.isAuthenticated) {
							//console.log("test", "test");
							Swal.fire({
								title: i18n.t("COMMON.MSG.TIMEOUT_ERROR"),
								text: i18n.t("COMMON.MSG.TIMEOUT_MESS"),
								icon: "warning",
								confirmButtonClass: "btn btn-secondary",
								heightAuto: false
							}).then(() => {
								router.push({ name: "login" });
							});

						}
					});
			});
		} else {
			context.commit(PURGE_AUTH);
		}
	},
	[SWITCH_ACCOUNT](context, account_data) {
		if (JwtService.getToken()) {
			return new Promise((resolve) => {
				ApiService.post("switch/account", account_data)
					.then((response) => {
						let data = response.data;
						if (data.code !== 200) {
							context.commit(PURGE_AUTH);
						} else {
							context.commit(SET_AUTH, data.result);
						}
						resolve(data);
					})
					.catch(() => {
						context.commit(PURGE_AUTH);
					})
					.finally(() => {
						if (!state.isAuthenticated) {
							router.push({ name: "login" });
						}
					});
			});
		} else {
			context.commit(PURGE_AUTH);
		}
	},
	[REGISTER](context, credentials) {
		return new Promise((resolve) => {
			ApiService.post("register", credentials, true)
				.then((response) => {
					let data = response.data;
					if (data.code == 200) {
						// Swal.fire({
						// 	title: "",
						// 	text: i18n.$t("AUTH.REGISTER.MSG_SUCCESSFUL_REGISTER"),
						// 	icon: "success",
						// 	confirmButtonClass: "btn btn-secondary",
						// 	heightAuto: false
						// });

						resolve(data);
					} else if (data.code == 2050) {
						context.commit(PURGE_AUTH);
						let msg = "";
						if (data.msg == "email_unique") {
							msg = i18n.t("AUTH.REGISTER.MSG_EMAIL_UNIQUE");
						} else {
							msg = Object.values(data.result).join("\n");
						}

						Swal.fire({
							title: i18n.t("COMMON.MSG.MSG_ERROR_TITLE"),
							text: msg,
							icon: "warning",
							confirmButtonClass: "btn btn-secondary",
							heightAuto: false
						});
					} else {
						context.commit(PURGE_AUTH);
						Swal.fire({
							title: i18n.t("COMMON.MSG.MSG_ERROR_TITLE"),
							text: data.msg,
							icon: "error",
							confirmButtonClass: "btn btn-secondary",
							heightAuto: false
						});
					}
				})
				.catch(({ response }) => {
					var error = response.data.errors ? response.data.errors : null;
					context.commit(SET_ERROR, error);
				});
		});
	},
	[EMAIL_VERIFICATION](context, vtoken) {
		return new Promise((resolve) => {
			ApiService.post("email/verification", vtoken, true)
				.then((response) => {
					let data = response.data;
					if (data.code === 200) {
						context.commit(PURGE_AUTH);
						resolve(data);
					} else {
						context.commit(PURGE_AUTH);
						Swal.fire({
							title: "",
							text: data.msg,
							icon: "error",
							confirmButtonClass: "btn btn-secondary",
							heightAuto: false
						});
					}
				})
				.catch(() => {
					context.commit(PURGE_AUTH);
					Swal.fire({
						title: "",
						text: "Cannot connect to server!",
						icon: "error",
						confirmButtonClass: "btn btn-secondary",
						heightAuto: false
					});
				});
		});
	},
	[SEND_VERIFICATION_EMAIL](context, email) {
		return new Promise((resolve) => {
			ApiService.post("email/resend-verification", email, true)
				.then((response) => {
					let data = response.data;
					if (data.code === 200) {
						context.commit(PURGE_AUTH);
						resolve(data);
					} else {
						context.commit(PURGE_AUTH);
						Swal.fire({
							title: "",
							text: data.msg,
							icon: "error",
							confirmButtonClass: "btn btn-secondary",
							heightAuto: false
						});
					}
				})
				.catch(() => {
					context.commit(PURGE_AUTH);
					Swal.fire({
						title: "",
						text: "Cannot connect to server!",
						icon: "error",
						confirmButtonClass: "btn btn-secondary",
						heightAuto: false
					});
				});
		});
	},
	[UPDATE_PASSWORD](context, payload) {
		const password = payload;

		return ApiService.put("password", password).then(({ data }) => {
			context.commit(SET_PASSWORD, data);
			return data;
		});
	},
	[FORGOT_PASSWORD](context, email) {
		return new Promise((resolve) => {
			ApiService.post("forgot-password", email, true)
				.then((response) => {
					let data = response.data;
					if (data.code === 200) {
						if (data.result.is_send === true) {
							Swal.fire({
								title: "",
								text: i18n.t('AUTH.FORGOT.MSG_FORGOT_PASSWORD_LINK_SEND'),
								icon: "success",
								confirmButtonClass: "btn btn-secondary",
								heightAuto: false
							});

							resolve(data);
						} else {
							context.commit(SET_ERROR, [i18n.t("AUTH.FORGOT.FAILED")]);
						}
					} else {
						context.commit(SET_ERROR, [data.msg]);
					}
				})
				.catch(({ response }) => {
					let data = response.data;
					context.commit(SET_ERROR, [data.msg]);
				});
		});
	},
	[RESET_SUB_ACCOUNT_ONE_TIME_PASSWORD](context, { id, password }) {
		return new Promise((resolve) => {
			ApiService.post(`sub-account/reset-one-time-password/${id}`, { new_password: password }, true)
				.then((response) => {
					let data = response.data;
					if (data.code === 200) {
						Swal.fire({
							title: "",
							text: i18n.t("AUTH.LOGIN.RESET_SUB_ACCOUNT_SUCCESS_MESSAGE"),
							icon: "success",
							heightAuto: false
						}).then(() => resolve(response));
					} else {
						context.commit(SET_ERROR, [data.msg]);
					}
				})
				.catch((response) => {
					let data = response.data;
					context.commit(SET_ERROR, [data.msg]);
				});
		});
	},
	[FORGOT_PASSWORD_VERIFY_TOKEN](context, vtoken) {
		return new Promise((resolve) => {
			ApiService.post("forgot-password/verify", vtoken, true)
				.then((response) => {
					let data = response.data;
					if (data.code === 200) {
						context.commit(PURGE_AUTH);
						resolve(data);
					} else {
						context.commit(PURGE_AUTH);
						Swal.fire({
							title: "",
							text: data.msg,
							icon: "error",
							confirmButtonClass: "btn btn-secondary",
							heightAuto: false
						});
					}
				})
				.catch(() => {
					context.commit(PURGE_AUTH);
					Swal.fire({
						title: "",
						text: "Cannot connect to server!",
						icon: "error",
						confirmButtonClass: "btn btn-secondary",
						heightAuto: false
					});
				});
		});
	},
	[FORGOT_PASSWORD_UPDATE_PASSWORD](context, payload) {
		return new Promise((resolve) => {
			ApiService.post("forgot-password/update", payload, true)
				.then((response) => {
					let data = response.data;
					if (data.code == 200) {
						resolve(data);
					} else {
						context.commit(PURGE_AUTH);
						Swal.fire({
							title: "",
							text: data.msg,
							icon: "error",
							confirmButtonClass: "btn btn-secondary",
							heightAuto: false
						});
					}
				})
				.catch(() => {
					context.commit(PURGE_AUTH);
					Swal.fire({
						title: "",
						text: "Cannot connect to server!",
						icon: "error",
						confirmButtonClass: "btn btn-secondary",
						heightAuto: false
					});
				});
		});
	},
	[SET_AUTH_TOKEN](context, payload) {
		context.commit(SET_AUTH, payload);
	},
	[UPDATE_CURRENT_USER_INFO](context, payload) {
		context.commit(SET_USER, payload);
	}
};

const mutations = {
	[CLEAR_ERROR](state) {
		state.errors = {};
	},
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_USER](state, user) {
		state.user.id = user.id;

		if (user.name !== undefined) {
			state.user.name = user.name;
		}

		if (user.nickname !== undefined) {
			state.user.nickname = user.nickname;
		}

		if (user.email !== undefined) {
			state.user.email = user.email;
		}

		if (user.login_id !== undefined) {
			state.user.login_id = user.login_id;
		}

		if (user.is_sub_account !== undefined) {
			state.user.is_sub_account = user.is_sub_account;
		}

		if (user.marking_list !== undefined) {
			if (Array.isArray(user.marking_list)) {
				state.user.marking_list = user.marking_list;
			}
		}

		if (user.all_account_list !== undefined) {
			if (Array.isArray(user.all_account_list)) {
				state.user.all_account_list = user.all_account_list;
			}
		}

		if (user.permission !== undefined) {
			if (Array.isArray(user.permission)) {
				state.user.permission = user.permission;
			}
		}

		if (user.sub_account_exist !== undefined) {
			state.user.sub_account_exist = user.sub_account_exist;
		}

		if (user.avatar !== undefined) {
			state.user.avatar = user.avatar;
		}

		if (user.status !== undefined) {
			state.user.status = user.status;
		}

		state.user.access_sub_account = user.access_sub_account != undefined ? user.access_sub_account : false;
	},
	[SET_AUTH](state, user) {
		state.isAuthenticated = user.pw_expire_date == 1 ? false:true;
		state.user.token = user.token;
		state.errors = {};
		JwtService.saveToken(state.user.token);
	},
	[SET_PASSWORD](state, password) {
		state.user.password = password;
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = {
			id: null,
			name: null,
			email: null,
			calling_code: null,
			contact_number: null,
			register_no: null,
			company_name: null,
			company_website: null,
			avatar: null,
			contact_pic: null,
			token: null,
			is_sub_account: null
		};
		state.errors = {};
		JwtService.destroyToken();
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};
