import ApiService from "@/core/services/api.service";
import i18n from "@/core/plugins/vue-i18n";

const warehouseSelection = function (marking_id = null, shipment_type_id = null, post_data = null) {
	if (post_data != null) {
		post_data.locale = i18n.locale;
		post_data.marking_id = marking_id;
		post_data.shipment_type_id = shipment_type_id;
	} else {
		post_data = {
			locale: i18n.locale,
			marking_id: marking_id,
			shipment_type_id: shipment_type_id
		};
	}
	if (typeof post_data.admin_portal == "undefined") post_data.admin_portal = 0;
	if (typeof post_data.ignore_support_other_country == "undefined") post_data.ignore_support_other_country = 1;
	return ApiService.post("warehouse/selection", post_data)
		.then((response) => {
			if (response.data.code == "200" && response.data.result.length > 0) {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(() => {
			return [];
		});
};

const getWarehouseCountry = async function (shipment_type_id = null) {
	let response = await ApiService.post("warehouse/country-selection", {
		locale: i18n.locale,
		shipment_type_id: shipment_type_id
	});
	let result = [];
	if (response.data.code == "200" && response.data.result.length > 0) {
		result = response.data.result;
	}
	return result;
};

const getShipmentType = async function (warehouse_id = null, warehouse_country = null) {
	let response = await ApiService.post(
		"shipment-type/selection",
		{
			warehouse_id: warehouse_id,
			warehouse_country: warehouse_country,
			locale: i18n.locale
		},
		false,
		1
	);
	return response.data.code == 200 && response.data.result.length > 0 ? response.data.result : null;
};

const getShipmentMode = function (shipment_type_id = null) {
	return ApiService.post("shipment-mode/selection", {
		shipment_type_id: shipment_type_id,
		locale: i18n.locale
	})
		.then((response) => {
			if (response.data.code == 200 && response.data.result.length > 0) {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(() => {
			return [];
		});
};

const getMarkingByWarehouse = async function (data = {}) {
	let response = await ApiService.post("profile/marking-selection", data);

	return response.data.code == "200" && response.data.result != null ? response.data.result : [];
};

const getCurrencies = async function (data = {}) {
	let response = await ApiService.post("currencies/selection", data);
	return response.data.code == 200 && response.data.result.length > 0 ? response.data.result : [];
};

const uploadFile = async function (data, updType = "image") {
	let response = await ApiService.uploadfile(`upload/${updType}`, data, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});

	if (response.status == "200" && response.data.code == "200") {
		if (response.data.result.fileDownloadUri != null || response.data.result.fileDownloadUri != undefined) {
			return response.data.result;
		} else {
			return null;
		}
	} else {
		return null;
	}
};

const getWarehouseType = async function (post_data = {}) {
	post_data.locale = i18n.locale;
	let response = await ApiService.post("warehouse-type/selection", post_data);
	return response.data.code == 200 ? response.data.result : [];
};

const getMovementType = async function (post_data = {}) {
	let response = await ApiService.post("move-type/selection", post_data);
	return response.data.code == 200 && response.data.result.length > 0 ? response.data.result : [];
};

const getCountries = async function (data = {}) {
	let response = await ApiService.post("countries/selection", data);
	if (response.data.code == 200 && response.data.result.length > 0) {
		return response.data.result;
	} else {
		return [];
	}
};

const getPopularCountries = async function (data = {}) {
	let result = [];
	let response = await ApiService.post("countries/popular-selection", data);
	if (response.data.code == 200 && response.data.result.length > 0) {
		if (Object.entries(data).length > 0) {
			let rdata = response.data.result;
			for (let idx in rdata) {
				let cresult = JSON.parse(rdata[idx].cresult);
				let cheader = cresult.find((rdata) => typeof rdata.header != "undefined");
				cresult[cresult.indexOf(cheader)].header = cheader.header == "POPULAR" ? i18n.t("COMMON.POPULAR_TITLE") : i18n.t("COMMON.SELECTION_ALL_TITLE");
				result = result.concat(cresult);
			}
		} else {
			result = response.data.result;
		}
	}
	return result;
};

const getCallingCode = function () {
	return ApiService.post("calling-code/selection")
		.then((response) => {
			if (response.status == 200 && response.data.result.length > 0) {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(() => {
			return [];
		});
};

const getStates = function (country_id) {
	return ApiService.post("states/selection", { country_id: country_id })
		.then((response) => {
			if (response.status == 200 && response.data.result.length > 0) {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(() => {
			return [];
		});
};

const getCities = function (country_id, state_id) {
	return ApiService.post("city/selection", { country_id: country_id, state_id: state_id })
		.then((response) => {
			if (response.status == 200 && response.data.result.length > 0) {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(() => {
			return [];
		});
};

const getPostcodes = function (country_id, state_id, city_id) {
	return ApiService.post("postcodes/selection", { country_id: country_id, state_id: state_id, city_id: city_id })
		.then((response) => {
			if (response.status == 200 && response.data.result.length > 0) {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(() => {
			return [];
		});
};

const getQuotationStatus = function () {
	return ApiService.post("quotation-management/status-selection", { locale: i18n.locale })
		.then((response) => {
			if (response.status == 200 && response.data.result.length > 0) {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(() => {
			return [];
		});
};

const getShipmentTerms = function (movement_type_id = null) {
	return ApiService.post("shipment-terms/selection", {
		movement_type_id: movement_type_id,
		locale: i18n.locale
	})
		.then((response) => {
			if (response.data.code == 200 && response.data.result.length > 0) {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(() => {
			return [];
		});
};

const getDeliveryZone = async function (country_id) {
	let response = await ApiService.post("area-group/selection", {
		locale: i18n.locale,
		country_id: country_id
	});
	let result = [];
	if (response.data.code == "200") {
		result = response.data.result;
	}

	return result;
};

const epoStatusSelection = async function () {
	let response = await ApiService.post("epo-status/selection", {
		locale: i18n.locale
	});
	return [{ id: 0, name: i18n.t('COMMON.STATUS.DRAFT'), status: 1 }].concat(response.data.code == "200" ? response.data.result : []);
}

const deliveryAddressSelection = async function (post_data = {}) {
	let response = await ApiService.post("delivery-address/selection", post_data);
	return response.data.code == "200" ? response.data.result : []
}

const syncToWms = async function (url, post_data = {}) {
	await ApiService.post(url, post_data);
}

const yesNoSelection = function () {
	return [
		{
			id: 1,
			name: i18n.t("COMMON.STATUS.YES")
		},
		{
			id: 0,
			name: i18n.t("COMMON.STATUS.NO")
		}
	]
};

export default {
	warehouseSelection,
	getShipmentType,
	getShipmentMode,
	getWarehouseCountry,
	getMarkingByWarehouse,
	getCurrencies,
	uploadFile,
	getWarehouseType,
	getMovementType,
	getCountries,
	getCallingCode,
	getStates,
	getCities,
	getPostcodes,
	getPopularCountries,
	getQuotationStatus,
	getShipmentTerms,
	getDeliveryZone,
	epoStatusSelection,
	deliveryAddressSelection,
	syncToWms,
	yesNoSelection
};
